.mg-rows {
    position:relative;
}
.mg-trigger{
    cursor: pointer;
}

.mg-space {
    display:none;
    position:relative;
    width:100%;
    float:left;
}
.mg-target {
    display:none;
    background:#fff;
    color:#888;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
}
.mg-trigger:hover{
    text-decoration: none;
}
.mg-target-open {
    position:absolute;
    top:0;left:0;right:0;
}
.mg-indicator {
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    height: 0;
    position: absolute;
    top: 0;
    width: 0;
}
.mg-close {
    display: none;
    background: url(../images/close.svg) no-repeat center center;
    cursor: pointer;
    height: 30px;
    padding: 7px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    z-index: 9999;
}
.mg-title{
    color: #060828;
    text-align: center;
    padding-top: 10px;
}


@media(max-width:992px){
.mg-project-title{
    padding-top: 15px;
}
.mg-close{
    right: 0;
    top: 0;
    background-color: #888;
}
}