body {
    font-family: 'Work Sans', sans-serif
}

.btn.focus,
.btn:focus,
button:focus {
    outline: 0
}

.breadcrumb {
    background: 0 0;
    margin-bottom: 0;
    padding: 15px 1px
}

.breadcrumb .breadcrumb-item a {
    font-size: 18px;
    color: #060828
}

.breadcrumb .breadcrumb-item a:hover {
    text-decoration: none
}

.breadcrumb .breadcrumb-item.active {
    font-size: 18px;
    font-weight: 600;
    color: #00c5de
}

.breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    font-weight: 400;
    color: #060828
}

.main_btn {
    min-width: 100px;
    height: 50px;
    padding: 0 30px;
    border-radius: 25px;
    background: #00c5de;
    border: 1px solid #00c5de;
    outline: 0;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    transition: .5s
}

.main_btn:hover {
    transition: .5s;
    background: #000;
    border: 1px solid #000;
    color: #fff
}

.main_btn a:hover {
    color: #fff !important
}

.main_btn a {
    color: #fff;
    text-decoration: none
}

.contact_us .main_btn:hover {
    border: 1px solid #fff
}

.main-content {
    display: grid;
    width: 100%
}

.main-content .item {
    display: grid;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap
}

.main-content button {
    position: relative;
    transition: .3s ease-in-out
}

.button-jittery button {
    animation: jittery 2s infinite
}

.button-jittery button:hover {
    animation: heartbeat .2s infinite
}

@keyframes jittery {

    5%,
    50% {
        transform: scale(1)
    }

    10% {
        transform: scale(.9)
    }

    15% {
        transform: scale(1.15)
    }

    20% {
        transform: scale(1.15) rotate(-10deg)
    }

    25% {
        transform: scale(1.15) rotate(15deg)
    }

    30% {
        transform: scale(1.15) rotate(-3deg)
    }

    35% {
        transform: scale(1.15) rotate(10deg)
    }

    40% {
        transform: scale(1.15) rotate(0)
    }
}

@keyframes heartbeat {
    50% {
        transform: scale(1.05)
    }
}

.main_title {
    font-size: 50px;
    font-weight: 700;
    color: #060828;
    width: 100%;
    float: left;
    line-height: 36px
}

.line {
    width: auto;
    float: left;
    text-align: center;
    line-height: 58px;
    font-size: 48px;
    font-weight: 600
}

.main_title .line::after {
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    background: #060828;
    margin: 30px 0 20px 0;
    margin-left: calc(50% - 50px)
}

.about_section .main_title .line::after {
    margin-left: 3px
}

.footer_section .main_title .line::after,
.testimonials_section .main_title .line::after {
    background: #fff
}

.main_title span {
    color: #00c5de
}

.sub_title {
    font-size: 22px;
    color: #060828;
    width: 100%;
    float: left;
    margin-bottom: 22px
}

.checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-top: 2px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0 !important
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 1px solid #4b4d6c;
    border-radius: 3px
}

.checkbox input:checked~.checkmark {
    background-color: transparent;
    border: 1px solid #4b4d6c;
    border-radius: 3px
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none
}

.checkbox input:checked~.checkmark:after {
    display: block
}

.checkbox .checkmark:after {
    left: 8px;
    top: 5px;
    width: 7px;
    height: 12px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(assets/images/caret-down.svg) transparent no-repeat 98.5% !important;
    background: url(assets/images/caret-down.svg) transparent no-repeat calc(100% - 0px) !important
}

select::-ms-expand {
    display: none
}

::placeholder {
    color: #4b4d6c;
    opacity: 1
}

:-ms-input-placeholder {
    color: #4b4d6c
}

::-ms-input-placeholder {
    color: #4b4d6c
}

.blog_search_main::placeholder,
.contact_us-main::placeholder {
    color: #4b4d6c;
    opacity: 1
}

.blog_search_main::placeholder,
.contact_us-main:-ms-input-placeholder {
    color: #4b4d6c
}

.blog_search_main::placeholder,
.contact_us-main::-ms-input-placeholder {
    color: #4b4d6c
}

::-webkit-scrollbar {
    width: 10px
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px
}

::-webkit-scrollbar-thumb {
    background: #00c5de;
    border-radius: 10px
}

::-webkit-scrollbar-thumb:hover {
    background: #00c5de
}

.our_services_circle_box {
    place-content: center
}

.value_added_content {
    margin-top: 20px
}

.service_img_circle img {
    height: 75px;
    width: 75px
}

.top_header {
    background-color: #fff;
    min-height: 80px;
    padding: 0;
    position: fixed;
    z-index: 1090;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .08)
}

.navbar {
    background-color: #fff;
    min-height: 80px;
    padding: 0
}

.navbar-brand,
.navbar-brand img {
    width: 100%;
    max-width: 300px
}

.top_header .nav-link {
    color: #000222;
    font-size: 16px;
    padding-right: 23px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 0
}

.mobile_menu_arrow {
    display: none
}

.menu_arrow::after {
    border: none;
    background: #fff0 url(assets/images/chevron-down.svg);
    width: 10px;
    height: 10px;
    background-position: 50% 50%;
    margin-top: 8px;
    margin-left: 5px;
    float: right;
    background-size: 18px
}

.nav-item {
    height: 80px;
    display: flex;
    align-items: center
}

.nav-item::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #00c5de;
    transition: .3s
}

.navbar-toggler-icon {
    background-image: url(./assets/images/menu.svg);
    width: 35px;
    height: 35px
}

.navbar-toggler {
    padding: 0
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    border-bottom: 5px solid #00c5de;
    border-radius: 10px;
    padding: 15px 0;
    min-width: 210px;
    margin-top: -1px
}

.dropdown-item {
    padding: 10px 10px 10px 20px;
    min-height: 40px;
    text-align: left
}

.submenu_list_title {
    font-size: 18px;
    padding-left: 15px;
    color: #00c5de
}

.submenu_list {
    padding: 0;
    list-style: none;
    float: left;
    width: 100%
}

.submenu_list li {
    padding: 2px 15px
}

.submenu_list li a {
    font-size: 14px;
    color: #060828;
    transition: .5s;
    text-decoration: none
}

.submenu_list li a:hover {
    transition: .5s;
    color: #00c5de;
    border-top: 1px solid #00c5de;
    padding: 3px 0;
    border-bottom: 1px solid #00c5de
}

.about_menu li a {
    font-size: 14px
}

#main_slider {
    margin: 0 auto
}

.slider_main {
    margin-top: 80px;
    width: 100%;
    float: left
}

.carousel-item {
    max-height: 100%;
    height: calc(100% - 80px);
    overflow: hidden
}

.slider_img_hover:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5)
}

.slider_img_hover img {
    transition: all 2s
}

.slider_img_hover:hover img {
    transition: all 2s;
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2)
}

.number_images {
    display: flex;
    width: 100%;
    float: left;
    justify-content: space-between;
    margin: 0 auto;
    z-index: 10
}

.number_images_row {
    margin-top: -12px
}

.d1 {
    animation-delay: .7s
}

.d2 {
    animation-delay: 1.4s
}

.d3 {
    animation-delay: 2.1s
}

.d4 {
    animation-delay: 2.8s
}

.d5 {
    animation-delay: 3.5s
}

.d6 {
    animation-delay: 4.2s
}

.d7 {
    animation-delay: 4.9s
}

.d8 {
    animation-delay: 5.6s
}

.d9 {
    animation-delay: 6.3s
}

.d10 {
    animation-delay: 7s
}

.carousel-caption {
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 0;
    /* top: 30%;
    margin: 0;
    padding: 0;
    right: 0;
    left: 0%; */
}

.navbar-toggler-icon {
    background-image: url(./assets/images/menu.svg);
    width: 35px;
    height: 35px
}

.slider_main .carousel-item:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

.slider_main .carousel-indicators .active {
    width: 50px;
    border-radius: 10px
}

.carousel-indicators li {
    background-image: linear-gradient(to right, #ff5c81, #444875);
    border: none;
    border-radius: 10px;
    height: 6px;
    opacity: 1;
    transition: all .5s ease
}

.slider_text_title {
    margin: 0
}

.slider_text {
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 30px;
    text-align: left;
    float: left;
    color: #fff !important
}

.slider_text_title span {
    font-size: 60px;
    font-weight: 700;
    padding: 20px 0;
    text-align: left;
    width: 100%;
    display: inline-block;
    text-transform: uppercase
}

.slider_text_title_color1 {
    color: #ff5c81 !important
}

.slider_small_title {
    font-size: 25px;
    line-height: 35px;
    font-weight: 500;
    text-align: left;
    float: left;
    padding: 0 0 15px 0;
    color: #ffc400 !important
}

.carousel-caption .main_btn {
    font-weight: 500;
    float: center
}

.slider-main-text {
    z-index: 10
}

.number_images_row {
    text-align: center
}

.number_images_row img {
    width: 100%
}

.number_images .number_images_row:first-child img {
    max-width: 930px;
    margin-left: 15px
}

.number_images_row h5,
.number_images_row p {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: center
}

.first_slide_bg {
    background: #444;
    padding: 20px 0;
}

.first_slide_bg img {
    max-height: 600px;
    float: right;
}

.slider_logo img {
    max-width: 150px;
    margin-left: 15px;
    z-index: 11
}

.slider_background {
    background: #444;
    padding-top: 30px
}

.main_slider_img {
    object-fit: cover;
    height: 575px
}

.about_section {
    padding: 80px 0;
    background: #f6f6f6
}

.about_section p {
    font-size: 18px;
    color: #060828;
    font-weight: 400;
    line-height: 30px;
    float: left;
    margin-bottom: 30px
}

.pink_box {
    width: 32%;
    height: 185px;
    float: left;
    background-image: url(./assets/images/home/pink.png);
    background-repeat: no-repeat;
    margin: 60px 30px 0 0;
    background-size: 170px;
    position: relative
}

.pink_box-info h4 {
    font-size: 16px;
    text-align: center;
    color: #060828
}

.pink_box-info h2 {
    font-size: 36px;
    color: #db7bd1;
    font-weight: 600;
    text-align: center;
    margin: 0
}

.pink_box-info {
    position: absolute;
    text-align: center;
    top: 50px;
    left: 30px
}

.purple_box {
    width: 28%;
    height: 160px;
    float: left;
    background-image: url(./assets/images/home/purple.png);
    background-repeat: no-repeat;
    background-size: 150px;
    margin: 0 30px 0 0;
    position: relative
}

.purple_box-info h4 {
    font-size: 14px;
    text-align: center;
    color: #060828
}

.purple_box-info h2 {
    font-size: 30px;
    color: #7e7ec9;
    font-weight: 600;
    text-align: center;
    margin: 0
}

.purple_box-info {
    position: absolute;
    text-align: center;
    top: 36px;
    left: 31px
}

.green_box {
    width: 28%;
    height: 160px;
    float: left;
    background-image: url(assets/images/home/green.png);
    background-repeat: no-repeat;
    background-size: 150px;
    margin: 110px 0 0 0;
    position: relative
}

.green_box-info h4 {
    font-size: 14px;
    text-align: center;
    color: #060828
}

.green_box-info h2 {
    font-size: 30px;
    color: #2ae130;
    font-weight: 600;
    text-align: center;
    margin: 0
}

.green_box-info {
    position: absolute;
    text-align: center;
    top: 44px;
    left: 24px
}

.blue_box {
    width: 100%;
    height: 270px;
    float: left;
    background-image: url(./assets/images/home/blue.png);
    background-repeat: no-repeat;
    margin: -80px 0 0 0;
    background-position: 130px;
    position: relative
}

.blue_box-info h4 {
    font-size: 24px;
    text-align: center;
    color: #060828
}

.blue_box-info h2 {
    font-size: 48px;
    color: #00c5de;
    font-weight: 600;
    text-align: center;
    margin: 0
}

.blue_box-info {
    position: absolute;
    text-align: center;
    top: 75px;
    left: 160px
}

.yellow_box {
    width: 56%;
    height: 205px;
    float: left;
    background-image: url(./assets/images/home/yellow.png);
    background-repeat: no-repeat;
    margin: -40px 0 0 20px;
    position: relative
}

.yellow_box-info h4 {
    font-size: 16px;
    text-align: center;
    color: #060828
}

.yellow_box-info h2 {
    font-size: 36px;
    color: #dea700;
    font-weight: 600;
    text-align: center;
    margin: 0
}

.yellow_box-info {
    position: absolute;
    text-align: center;
    top: 75px;
    left: 53px
}

.red_box {
    width: 36%;
    height: 205px;
    float: right;
    background-image: url(./assets/images/home/red.png);
    background-repeat: no-repeat;
    margin: -40px 20px 0 0;
    position: relative
}

.red_box-info h4 {
    font-size: 16px;
    text-align: center;
    color: #060828
}

.red_box-info h2 {
    font-size: 36px;
    color: #e24966;
    font-weight: 600;
    text-align: center;
    margin: 0
}

.red_box-info {
    position: absolute;
    text-align: center;
    top: 65px;
    left: 52px
}

.statistics-box {
    margin: 0 auto
}

.services_section {
    padding: 80px 0
}

.our-services {
    width: 100%;
    float: left;
    overflow: hidden
}

.services_slider_main {
    position: relative;
    width: 100%;
    float: left;
    padding-bottom: 30px
}

.leftRs:hover,
.rightRs:hover {
    transition: .3s;
    background: #060828
}

.leftRs:hover svg,
.rightRs:hover svg {
    transition: .3s;
    stroke: #fff
}

.services_slider_text {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    line-height: 26px;
    padding: 13px 0px 0 0px;
}

.services_slider_title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    line-height: 28px;
    padding-top: 25px;
}

.slider_box {
    border: 1px solid #060828;
    border-radius: 25px;
    margin: 15px 0;
    transition: .3s;
    padding: 30px 15px;
    text-align: center;
    /* height: 265px; */
    width: 100%;
}

.slider_box:hover {
    box-shadow: 0 0 8px 6px #efefef
}

.our_services_icons_fix {
    width: 100%;
    max-width: 102px
}

.carousel-control-prev-icon {
    background-image: url(./assets/images/chevron-left.svg);
    width: 30px;
    height: 30px
}

.carousel-control-next-icon {
    background-image: url(./assets/images/chevron-right.svg);
    width: 30px;
    height: 30px
}

.leftRs {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 100;
    left: -15px;
    background: #fff;
    width: 50px;
    height: 50px;
    border: 1px solid #060828;
    border-radius: 999px
}

.rightRs {
    position: absolute;
    margin: 0 auto;
    top: 0;
    right: -15px;
    background: #fff;
    bottom: 0;
    z-index: 100;
    width: 50px;
    height: 50px;
    border: 1px solid #060828;
    border-radius: 999px
}

.leftRs,
.rightRs {
    margin-top: 135px
}

.testimonials_video_slider_main .leftRs,
.testimonials_video_slider_main .rightRs {
    margin-top: 75px
}

.testimonials_slider_main .leftRs,
.testimonials_slider_main .rightRs {
    margin-top: 160px
}

.testimonial_video_button {
    width: 100%;
    float: left;
    position: absolute;
    height: calc(100% - 120px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer
}

.testimonial_video_button .leftRs {
    position: relative;
    left: -15px;
    background: #fff;
    width: 50px;
    height: 50px;
    border: 1px solid #060828;
    border-radius: 999px;
    margin: 0
}

.testimonial_video_button .rightRs {
    position: relative;
    margin: 0;
    background: #fff;
    width: 50px;
    height: 50px;
    border: 1px solid #060828;
    border-radius: 999px;
    float: right;
    left: 15px
}

.resCarousel {
    position: relative;
    padding-bottom: 60px
}

.portfolio_section {
    background: #f6f6f6;
    padding: 80px 0
}

.portfolio_box img {
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px
}

.portfolio_box_title {
    height: 60px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    font-size: 16px;
    font-weight: 600;
    color: #060828
}

.portfolio_box {
    border-radius: 25px;
    transition: .5s;
    position: relative
}

.portfolio_box:hover {
    box-shadow: 0 0 8px 6px #efefef
}

.portfolio_box:hover .portfolio_box_hover_box {
    background: #000000bf;
    transition: .5s;
    opacity: 1
}

.portfolio_box_hover_box {
    transition: .5s;
    border-radius: 25px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: absolute;
    width: 100%;
    height: calc(100% - 60px);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: #fff;
    text-align: center
}

.mg-target-open {
    padding: 0 !important
}

.partner_section {
    padding: 80px 0
}

.partner_logo_box {
    place-content: center
}

.partner_logo_img {
    max-width: 260px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eaeaea;
    min-height: 100px;
    transition: .3s;
    margin: 10px 0
}

.partner_logo_img:hover {
    box-shadow: 0 0 10px 3px #e7e7e7
}

.expert_section {
    background: #f6f6f6;
    padding: 50px 0
}

.expert_text {
    font-size: 30px;
    font-weight: 700;
    color: #060828;
    line-height: 40px
}

.testimonials_section {
    padding: 80px 0;
    background-image: url(./assets/images/testimonial_bg.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

.testimonials_section .media {
    position: relative;
    padding: 0 0 0 15px;
    align-items: center
}

.testimonials_section .media img {
    width: 80px;
    height: 80px;
    display: block;
    border-radius: 50%
}

.testimonials_section .media .media-left {
    margin-right: 20px
}

.testimonials_section .testimonial {
    color: #060828;
    position: relative;
    padding: 30px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 30px;
    height: 300px;
    text-align: center
}

.testimonials_section .testimonial::after {
    content: "";
    width: 15px;
    height: 15px;
    display: block;
    background: #fff;
    border-width: 0 0 1px 1px;
    position: absolute;
    bottom: -8px;
    left: 46px;
    transform: rotateZ(-46deg)
}

.testimonials_section .overview .details {
    font-size: 18px;
    color: #fff;
    line-height: 20px
}

.testimonials_section .overview b {
    color: #fff;
    font-size: 22px;
    line-height: 22px
}

.testimonials_section .main_title,
.testimonials_section .sub_title {
    color: #fff
}

.testimonial-wrapper {
    padding-top: 50px;
    width: calc(100% / 3 - 45px);
    float: left;
    margin-right: 15px
}

.new_testi {
    width: 100%;
    float: left;
    overflow: hidden;
    position: relative
}

.new_testi .slick-prev {
    position: absolute;
    border: none;
    background-color: transparent;
    outline: 0;
    font-size: 50px;
    top: 40%;
    color: rgba(0, 0, 0, .4);
    transition: .15s linear;
    border: 1px solid #00c5de;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #00c5de;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-size: 0;
    margin-left: -30px
}

.new_testi .slick-next {
    position: absolute;
    border: none;
    background-color: transparent;
    outline: 0;
    font-size: 50px;
    top: 40%;
    color: rgba(0, 0, 0, .4);
    transition: .15s linear;
    border: 1px solid #00c5de;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #00c5de;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-size: 0;
    margin-left: -30px
}

.testimonial_quotes {
    margin-top: -60px;
    padding-bottom: 5px
}

.testimonial_video_box {
    border-radius: 20px;
    border: 1px solid #fff;
    overflow: hidden;
    cursor: pointer;
    position: relative
}

.MS-controls {
    position: relative;
    width: 100%;
    float: left
}

.MS-controls button {
    position: absolute;
    border: none;
    background-color: transparent;
    outline: 0;
    font-size: 50px;
    top: 40%;
    color: rgba(0, 0, 0, .4);
    transition: .15s linear;
    border: 1px solid #00c5de;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #00c5de;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -290px;
    margin-left: -10px
}

#mixedSlider .MS-controls button:hover {
    color: rgba(0, 0, 0, .8)
}

.MS-controls .MS-left {
    left: -15px
}

.MS-controls .MS-right {
    right: -15px
}

.category-main .category-list .category-list-img {
    min-width: 275px;
    height: 236px;
    margin-right: 17px;
}


.category-main .category-list .category-list-img img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}

@media (max-width:991px) {
    #mixedSlider .MS-content .item {
        width: 50%
    }
}

@media (max-width:992px) {
    #mixedSlider .MS-controls button {
        font-size: 30px
    }
}

@media (max-width:767px) {
    #mixedSlider .MS-content .item {
        width: 50%
    }

    #mixedSlider .MS-controls button {
        font-size: 20px
    }

    #mixedSlider .MS-controls .MS-left {
        left: -10px
    }

    #mixedSlider .MS-controls .MS-right {
        right: -10px
    }
}

@media (max-width:576px) {
    #mixedSlider .MS-content .item {
        width: 100%
    }
}

#testimonials_video {
    position: relative
}

#testimonials_video .MS-content {
    white-space: nowrap;
    overflow: hidden;
    width: 100%
}

#testimonials_video .MS-content .item {
    display: inline-block;
    width: 25%;
    position: relative;
    vertical-align: top;
    overflow: hidden;
    height: 100%;
    white-space: normal;
    padding: 0 10px
}

#testimonials_video .MS-content .item a {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000006e;
    z-index: 99;
    float: right;
    cursor: pointer;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center
}

#testimonials_video .MS-controls button {
    position: absolute;
    border: none;
    background-color: transparent;
    outline: 0;
    font-size: 50px;
    top: 95px;
    color: rgba(0, 0, 0, .4);
    transition: .15s linear
}

.testimonials_video_slider_main {
    position: relative;
    width: 100%;
    float: left
}

#testimonials_video .MS-controls button:hover {
    color: rgba(0, 0, 0, .8)
}

#testimonials_video .MS-controls .MS-left {
    left: 0
}

#testimonials_video .MS-controls .MS-right {
    right: 0
}

@media (max-width:991px) {
    #testimonials_video .MS-content .item {
        width: 25%
    }
}

@media (max-width:992px) {
    #testimonials_video .MS-controls button {
        font-size: 30px
    }
}

@media (max-width:767px) {
    #testimonials_video .MS-content .item {
        width: 50%;
        margin-bottom: 20px
    }

    #testimonials_video .MS-content {
        white-space: unset
    }

    #testimonials_video .MS-controls button {
        font-size: 20px
    }

    #testimonials_video .MS-controls .MS-left {
        left: -10px
    }

    #testimonials_video .MS-controls .MS-right {
        right: -10px
    }
}

.modal-body {
    position: relative;
    padding: 0
}

.close {
    position: absolute;
    top: -30px;
    right: 0;
    z-index: 999;
    font-size: 2rem;
    font-weight: 400;
    color: #fff;
    opacity: 1
}

.current_openings .close {
    position: absolute;
    top: 5px;
    margin-right: -2px
}

.current_openings .close:hover {
    color: #fff
}

.teams_section {
    padding: 80px 0
}

.teams_box {
    margin-top: 20px
}

.team_single_box {
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    margin-bottom: 2px;
    transition: all .3s;
    overflow: hidden
}

.team_single_box img {
    transition: all 2s;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px
}

.team_name {
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
    flex-direction: column;
    border-top: 1px solid #dfdfdf
}

.team_name span {
    width: 100%;
    float: left;
    text-align: center;
    color: #00c5de;
    font-size: 16px;
    font-weight: 400
}

.team_single_box_img {
    overflow: hidden
}

.team_single_box:hover img {
    transition: all 2s;
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2)
}

.client_section {
    padding: 80px 0;
    background: #f6f6f6
}

.client_logo {
    max-width: 260px;
    height: 100px;
    border: 1px solid #ddd;
    border-radius: 70px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff
}

.blog_sectoin {
    padding: 100px 0
}

.blog_box {
    width: 100%;
    float: left;
    padding: 30px 35px;
    margin-top: 20px;
    margin-bottom: 70px;
    transition: all .3s;
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .16)
}

.blog_box:hover {
    transition: all .3s;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, .1)
}

.bloger_name_date {
    font-size: 18px;
    color: #060828;
    margin-bottom: 15px
}

.blog_by {
    color: #00c5de
}

.blog_img {
    border: 1px solid #d9d9d9
}

.blog_title {
    font-size: 22px;
    font-weight: 600;
    margin-top: 15px;
    padding-bottom: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 4px
}

.blog_read_more {
    padding-top: 15px;
    border-top: 2px dashed #bdbdbd;
    margin-top: 10px
}

.blog_read_more a {
    text-decoration: none;
    font-size: 22px;
    color: #bdbdbd
}

.blog_read_more a svg {
    transition: .5s
}

.blog_read_more a:hover svg {
    transition: .5s;
    margin-left: 5px
}

.blog_search_box {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
    height: 40px;
    border: 1px solid #4e5154;
    outline: 0;
    transition: 1s;
    padding: 0 20px;
    border-radius: 20px
}

.blog_search_box:focus,
.blog_search_box:hover {
    transition: 1s;
    max-width: 400px;
    border-radius: 20px
}

/* .sb-icon {
    background: #1ccbe1;
    height: 50px!important;
    width: 50px!important;
    border-radius: 50%;
    padding-top: 5px;
    transition: all .5s
} */

svg:not(:root).svg-inline--fa {
    color: #fff
}

/* .sb-icon:hover {
    margin-top: -10px;
    background-color: #00c5de;
    border: 1px solid #00c5de;
    animation: heartbeat .2s infinite
} */

.footer_section {
    background: #060828;
    padding: 60px 0 0 0
}

.footer_section .main_title,
.footer_section .sub_title {
    color: #fff
}

.footer_section input,
.footer_section select,
.footer_section textarea {
    width: 100%;
    min-height: 45px;
    padding: 0 15px;
    border: 1px solid #f1f1f180;
    border-radius: 5px;
    background: 0 0;
    font-size: 16px;
    color: #fff;
    outline: 0;
    transition: .5s
}

.footer_textbox {
    margin-bottom: 20px
}

.check_label {
    display: flex;
    align-items: center;
    color: #fff;
    margin: 0
}

.footer_section input:focus,
.footer_section input:hover,
.footer_section select:focus,
.footer_section select:hover,
.footer_section textarea:focus,
.footer_section textarea:hover {
    transition: .5s;
    border-color: #fff
}

.footer_section textarea {
    padding-top: 15px;
    resize: none
}

.footer_section select {
    color: #ffffff80;
    padding: 10px 15px
}

.footer_section select option {
    background: #060828
}

.footer_section .white_option {
    color: #fff
}

.footer_section .main_btn {
    margin-top: 30px
}

.footer_title {
    font-size: 30px;
    color: #fff;
    font-weight: 600;
    text-align: left;
    line-height: 36px;
    padding-bottom: 5px;
    border-bottom: 1px solid #fff;
    margin-bottom: 30px
}

.footer_sub_title {
    font-size: 22px;
    color: #00b3cb;
    text-align: left;
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase
}

.footer_sub_title img {
    width: 24px;
    margin-top: -4px;
    margin-right: 8px
}

.footer_address {
    font-size: 16px;
    color: #fff;
    text-align: left;
    line-height: 25px;
    margin-top: 5px;
    padding-left: 33px;
    margin-bottom: 30px
}

.footer_call,
.footer_call a {
    font-size: 20px;
    color: #fff;
    text-align: left
}

.footer_call img {
    width: 20px;
    margin-right: 15px
}

.footer_copyright {
    font-size: 18px;
    color: #fff;
    text-align: center;
    margin-top: 70px;
    display: flex;
    align-items: center
}

.social_icon_box {
    height: 45px;
    width: 45px;
    background-color: #00c5de;
    border: 1px solid #00c5de;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 20px;
    cursor: pointer;
    padding: 7px 0;
    transition: all .5s
}

.social_icon_box:hover {
    margin-top: -10px;
    background-color: #00c5de;
    border: 1px solid #00c5de;
    animation: heartbeat .2s infinite
}

.social_icon_box:hover svg {
    fill: #00c5de
}

.bottom_footer {
    width: 100%;
    float: left;
    height: 50px;
    background: #00c5de;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px
}

.contact_us {
    padding-bottom: 50px
}

.footer_social_icon_section {
    float: left;
    display: flex
}

.social_icon_title {
    width: 100%;
    float: left;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 10px
}

.about_us_page {
    background-image: url(./assets/images/about_banner.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 380px;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center
}

.banner_title {
    font-size: 50px;
    color: #fff;
    font-weight: 700
}

.banner_sub_title {
    font-size: 30px;
    font-weight: 400;
    color: #fff
}

.banner_title::after {
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    background: #fff;
    margin: 10px 0 20px 0;
    margin-left: calc(50% - 50px)
}

.about_we {
    background: #f6f6f6;
    padding: 80px 0
}

.about_we p {
    color: #060828;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 40px;
    float: left
}

.timeline_section {
    padding: 80px 0
}

.timeline_logo {
    margin-bottom: 60px
}

.left_timeline {
    border-right: 2px dashed #00c5de;
    padding: 30px 50px 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end
}

.right_timeline {
    padding: 30px 0 30px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start
}

.timeline_title {
    font-size: 36px;
    font-weight: 700;
    color: #00c5de
}

.left_timeline p,
.right_timeline p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #060828
}

.timeline_years {
    width: 200px;
    margin: 0 auto;
    height: 60px;
    box-shadow: 0 0 5px .5px #e7e7e7;
    border-radius: 30px;
    border: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
    margin-bottom: 3px
}

@keyframes textclip {
    to {
        background-position: 200% center
    }
}

.linear-wipe {
    text-align: center;
    background: linear-gradient(to right, #060828 10%, #fff 50%, #00c5de 60%);
    background-size: 200% auto;
    color: #060828;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: shine 1s linear infinite;
    animation: shine 1s linear infinite;
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    margin: 0
}

@-webkit-keyframes shine {
    to {
        background-position: 200% center
    }
}

@keyframes shine {
    to {
        background-position: 200% center
    }
}

.about_company {
    background: #f6f6f6;
    padding: 80px 0
}

.about_title {
    font-size: 36px;
    color: #00c5de;
    font-weight: 700;
    line-height: 36px;
    padding-bottom: 15px
}

.about_large_text {
    font-size: 22px;
    font-weight: 500;
    color: #060828;
    line-height: 28px
}

.about_text {
    font-size: 18px;
    color: #060828;
    font-weight: 400;
    line-height: 30px;
    float: left
}

.modal_factor_section {
    padding: 80px 0
}

.modal_fector {
    background: #060828;
    border-radius: 10px;
    padding: 40px 0
}

.modal_circle {
    width: 170px;
    height: 170px;
    margin: 0 auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 10px solid #0e113d
}

.modal_title {
    font-size: 24px;
    color: #42cde3;
    text-align: center;
    font-weight: 600;
    line-height: 30px;
    padding-top: 25px
}

.modal_title::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background: #42cde3;
    margin: 10px 0 10px 0;
    margin-left: calc(50% - 25px)
}

.modal_list {
    padding: 0;
    color: #fff;
    font-size: 20px;
    text-align: center
}

.modal_list li {
    line-height: 36px;
    list-style: none
}

.modal_fector .main_btn:hover {
    border: 1px solid #fff
}

.modal_box {
    margin-bottom: 100px
}

.modal_box_right {
    padding: 25px;
    box-shadow: 0 0 10px 3px #e7e7e7
}

.modal_box_right .main_btn {
    margin-top: 20px
}

.modal_box2 .modal_title2 {
    font-size: 25px;
    color: #00c5de;
    font-weight: 700;
    word-break: break-word
}

.modal_box2 p {
    font-size: 16px;
    color: #060828;
    line-height: 25px;
    font-weight: 400;
    padding-top: 15px
}

.modal_box_space {
    margin-top: 133px
}

.modal_btn_right {
    margin-top: -25px;
    margin-right: 50px;
    font-weight: 400;
    text-transform: uppercase
}

.modal_btn_left {
    margin-top: -25px;
    margin-left: 50px;
    font-weight: 400;
    text-transform: uppercase
}

.modal_expert {
    border-top: 1px solid #ebebeb;
    padding: 50px 0
}

.modal_expert_text {
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    line-height: 36px;
    padding-bottom: 45px
}

.modal_expert .main_btn {
    font-weight: 400
}

.contact_us-main {
    padding: 80px 0
}

.contact_us-main .contact_us {
    background: 0 0;
    padding: 28px 0 0 0
}

.contact_us-main .contact_us input,
.contact_us-main .contact_us select,
.contact_us-main .contact_us textarea {
    color: #060828;
    border-color: #4b4d6c;
    min-height: 60px;
    font-size: 18px
}

.contact_us-main .check_label,
.contact_us-main .checkbox {
    color: #060828
}

.contact_us-main .contact_us input:focus,
.contact_us-main .contact_us input:hover,
.contact_us-main .contact_us select:focus,
.contact_us-main .contact_us select:hover,
.contact_us-main .contact_us textarea:focus,
.contact_us-main .contact_us textarea:hover {
    border-color: #060828
}

.contact_us-main .contact_us select option {
    background: #fff;
    color: #060828;
    font-family: 'Work Sans', sans-serif !important
}

.contact_us-main .contact_us .white_option {
    color: #060828;
    font-family: 'Work Sans', sans-serif !important
}

.contact_us-main .contact_us .checkbox .checkmark::after {
    border: solid #060828;
    border-width: 0 3px 3px 0
}

.contact_us {
    /* background-image: url(./assets/images/footer/footer_bg.png); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover
}

.only-copy-right {
    background: #060828;
    padding: 20px 0
}

.only-copy-right .footer_copyright {
    margin-top: 0
}

.project-career {
    padding: 80px 0
}

.project-details {
    display: inline-block;
    width: 100%;
    text-align: center
}

.project-details svg {
    color: #00c5de;
    width: 50px;
    margin: 60px 0 30px 0
}

.project-details a {
    font-size: 20px;
    color: #060828;
    font-weight: 400;
    display: inline-block;
    width: 100%
}

.find-us {
    background-color: #f6f6f6;
    padding: 80px 0
}

.find-location {
    margin-top: 100px;
    position: relative;
    display: flex;
    justify-content: center
}

.find-location .location-details {
    border: 1px solid #060828;
    background-color: #fff;
    text-align: center;
    padding: 60px 30px 30px;
    border-radius: 8px;
    float: left;
    width: 100%;
    color: #060828
}

.find-location .location-details:hover {
    transition: .3s;
    background: linear-gradient(141deg, #9fb8ad 0, #00c5de 51%, #2cb5e8 75%);
    color: #fff
}

.find-location .location-details h2 {
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 20px
}

.find-location .location-details p {
    font-size: 18px;
    margin: 0;
    width: 70%;
    text-align: center;
    display: inline-block
}

.find-location .circle-icon {
    background-color: #fff;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .2);
    margin-top: -60px;
    z-index: 1;
    position: absolute
}

.find-location .circle-icon img {
    width: 75px
}

.hire_developer {
    padding: 60px 15px 30px !important
}

.hire_developer p {
    width: 100% !important
}

.hire_developer .app_feature_box {
    padding: 43px 20px
}

.hire_developer .app_feature_title {
    color: #060828
}

.hire_developer_business_modal .app_feature_box {
    padding: 35px 0
}

.business_modal {
    border-bottom: 1px solid #eaeaea;
    padding: 15px 0
}

.business_modal_title {
    color: #060828;
    font-size: 20px;
    font-weight: 700;
    text-align: center
}

.business_modal_text {
    font-size: 18px;
    text-align: center;
    color: #060828
}

.hire_developer_business_modal .business_modal:last-child {
    border-bottom: none
}

.tools_title {
    font-size: 36px;
    font-weight: 700;
    color: #060828;
    padding-top: 30px
}

.python_development .app_detail_box {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: center
}

.hire_developer_Industries {
    padding: 90px 0;
    background: #f6f6f6
}

.hire_developer_Industries .service_img_circle {
    background: #fff
}

.career_about {
    padding: 80px 0;
    background: #f6f6f6
}

.career_about .main_btn {
    font-weight: 400;
    padding: 0 50px;
    display: flex;
    align-items: center;
    text-decoration: none
}

.career_content p {
    margin-top: 20px;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0
}

.view_more_photos {
    font-size: 18px;
    font-weight: 600;
    padding-top: 8px;
    float: left;
    padding-right: 20px
}

.work_culture {
    padding: 80px 0
}

.work_culture_title {
    font-size: 22px;
    color: #060828;
    font-weight: 500
}

.work_culture .sub_title {
    margin-bottom: 0
}

.work_culture_content p {
    font-size: 18px;
    color: #060828;
    font-weight: 400;
    margin: 0
}

.work_culture_about {
    margin-top: 25px
}

.work_culture_img {
    width: 90px;
    padding-top: 8px
}

.work_culture_inner_content {
    width: calc(100% - 90px)
}

.rang_services_box {
    margin-bottom: 40px
}

.work_culture_img img {
    width: 100%;
    max-width: 60px;
    height: auto
}

.life_section {
    background-color: #f6f6f6;
    padding: 80px 0
}

.life_image_section {
    margin-top: 10px
}

#demo {
    height: 100%;
    position: relative;
    overflow: hidden
}

.life_section .green {
    background-color: #6fb936
}

.life_section .thumb {
    margin-bottom: 30px
}

.life_section img.zoom {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    object-fit: cover;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    filter: grayscale(100%)
}

.life_section .transition {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    filter: initial !important
}

.life_section .modal-header {
    border-bottom: none
}

.life_section .modal-title {
    color: #000
}

.life_section .modal-footer {
    display: none
}

.employee_speaks {
    padding: 80px 0
}

.employee_speaks .carousel-indicators {
    bottom: -11%
}

.employee_speaks .carousel-indicators li {
    background-color: #000
}

.employee_detail_slider {
    margin: 50px 0
}

.employee_speaks_slider p {
    font-size: 18px;
    margin: 10px 0 35px 0;
    color: #060828
}

.employee_name {
    font-size: 22px;
    line-height: 24px;
    color: #060828;
    margin-top: 15px
}

.employee_designation {
    font-size: 16px;
    line-height: 24px;
    color: #909090
}

.current_openings {
    background-color: #f6f6f6;
    padding: 80px 0
}

.designation_title {
    font-size: 18px;
    line-height: 30px;
    color: #060828;
    font-weight: 600
}

.designation_content {
    font-size: 18px;
    line-height: 26px;
    color: #060828
}

.employee_speaks .carousel-inner {
    height: auto
}

.employee_speaks .carousel-indicators .active {
    width: 50px;
    border-radius: 10px
}

.top_modal_header .modal-title {
    text-align: left;
    font-size: 28px;
    color: #fff
}

.top_modal_header {
    background-color: #00c5de;
    padding: 20px
}

.top_modal_header .modal-header {
    border-bottom: none
}

.title_bottom_text {
    font-size: 18px;
    color: #fff
}

.inner_modal_section {
    padding: 30px 0
}

.modal_small_title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #060828
}

.modal_description {
    font-size: 16px;
    line-height: 28px;
    color: #000222;
    margin-bottom: 15px
}

.modal_ul {
    position: relative;
    margin: 0
}

.modal_ul li {
    margin-bottom: 5px
}

.form_section {
    float: left;
    width: 100%;
    box-shadow: 0 0 10px 3px #f6f6f6;
    padding: 20px;
    margin-top: 15px
}

.form_section .custom-file-label,
.form_section input {
    width: 100%;
    min-height: 45px;
    padding: 0 15px;
    border: 1px solid #060828;
    border-radius: 5px;
    background: 0 0;
    font-size: 16px;
    color: #060828;
    outline: 0;
    transition: .5s;
    display: flex;
    align-items: center
}

.form_section .custom-file-label::after {
    min-height: 43px;
    padding-top: 9px
}

.form_section .custom-file {
    min-height: 45px
}

.current_openings .modal-dialog {
    margin: 100px auto !important
}

.current_openings .modal-header {
    padding: 0
}

.custom-file-label::after {
    background-color: #00c5de;
    color: #fff;
    border: 1px solid #00c5de
}

.current_openings .blog_box {
    background-color: #fff;
    margin-bottom: 15px
}

.current_openings .blog_read_more a {
    color: #01b3e3
}

.contact_deatail {
    background-color: #fff;
    padding: 50px 0
}

.contact_heading {
    font-size: 30px;
    color: #060828;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 35px
}

.contact_content {
    font-size: 22px;
    line-height: 26px;
    color: #060828
}

.contact_deatail_content .main_btn {
    font-weight: 400;
    padding: 0 88px
}

.col-center {
    margin: 0 auto;
    float: none !important
}

.current_openings .blog_read_more {
    margin-top: 20px
}

.designation_title_top {
    font-size: 22px;
    color: #060828;
    font-weight: 600
}

.designation_content_space {
    margin-bottom: 10px
}

.current_openings .main_btn {
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    padding: 0 20px
}

.current_openings .main_btn img {
    margin-left: -20px;
    transition: .3s;
    opacity: 0;
    max-width: 22px
}

.current_openings .main_btn:hover img {
    margin-left: 0;
    transition: .3s;
    opacity: 1
}

.current_openings .main_btn:hover a {
    color: #fff !important
}

.range_services {
    padding: 90px 0
}

.learn_more {
    background-color: #fff;
    border: 1px solid #595a70;
    min-width: 165px;
    height: 40px;
    font-size: 18px;
    color: #2f314a;
    line-height: 36px;
    padding: 0 25px;
    transition: .5s
}

.learn_more:hover {
    background-color: #000;
    color: #fff;
    transition: .5s
}

.range_services .work_culture_content p {
    min-height: 80px
}

.services_about {
    padding: 80px 0;
    background-color: #f6f6f6
}

.our_platforms {
    padding: 80px 0
}

.our_platforms .sub_title {
    margin-bottom: 0
}

.our_platforms_box {
    display: flex;
    align-self: stretch
}

.development_box {
    max-width: 260px;
    height: 90px;
    border: 1px solid #060828;
    text-align: center;
    border-radius: 8px;
    position: relative;
    margin: 0 auto
}

.development_box:hover {
    border: 1px solid #00c5df
}

.development_box:hover .grey_border_circle {
    margin-top: -15px;
    transition: all .4s;
    box-shadow: 0 0 10px 3px #e7e7e7;
    border: 1px solid #00c5df
}

.grey_border_circle {
    height: 75px;
    width: 75px;
    border: 1px solid #d3d3d3;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: -45%;
    z-index: 10;
    background: #fff;
    border: 1px solid #060828;
    transition: all .4s;
    padding: 10px
}

.development_title {
    font-size: 18px;
    color: #060828;
    line-height: 18px;
    margin-top: 50px
}

.our_platform_box {
    margin-top: 70px
}

.top_notch {
    padding: 90px 0 50px;
    background: #f6f6f6
}

.top_notch .client_logo {
    border-radius: 0;
    border: none
}

#TopNotch {
    position: relative
}

#TopNotch .MS-content {
    white-space: nowrap;
    overflow: hidden
}

#TopNotch .MS-content .item {
    display: inline-block;
    position: relative;
    vertical-align: top;
    overflow: hidden;
    height: 100%;
    white-space: normal;
    line-height: 50px;
    vertical-align: middle
}

.white_bg_counter_box {
    height: 150px;
    max-width: 260px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    flex-direction: column;
    padding: 0 10px;
    margin: 0 auto;
    margin-top: 30px;
    transition: all .3s
}

.white_bg_counter_box:hover {
    transition: all .3s;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, .1)
}

.white_bg_counter_box p {
    font-size: 48px;
    font-weight: 700;
    color: #060828;
    line-height: 48px;
    margin: 0
}

.counter_content {
    font-size: 18px;
    color: #060828;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    margin-top: 10px
}

.counter_section {
    padding: 15px 0 0 0
}

.our-portfolio {
    padding: 80px 0;
    background-color: #f6f6f6
}

#filters {
    margin: 28px 0 50px;
    padding: 0;
    list-style: none;
    width: 100%;
    display: inline-block
}

#filters li {
    float: left
}

#filters li span {
    display: inline-block;
    padding: 10px 51px;
    text-decoration: none;
    color: #00c5df;
    cursor: pointer;
    border-radius: 50px;
    background: #fff;
    border: 1px solid #ececec;
    font-size: 24px;
    margin: 0 15px
}

#filters li span.active {
    background: #00c5df;
    color: #fff;
    border: 1px solid #00c5df;
    font-weight: 600
}

#portfoliolist {
    width: 100%;
    display: inline-block
}

#portfoliolist .portfolio {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    display: none;
    float: left;
    overflow: hidden
}

.build_image span img {
    margin-right: 15px
}

.wordPress_development_about {
    padding: 80px 0
}

.wordPress_development_about .sub_title {
    margin-bottom: 0
}

.Company_offers {
    padding: 80px 0;
    background-color: #f6f6f6
}

.Company_offers_section .carousel-item {
    height: 100%;
    min-height: 640px
}

.Company_offers_section .carousel-indicators {
    bottom: 70px
}

.offers_slider_section .carousel-indicators .active {
    width: 50px;
    border-radius: 10px
}

.Company_offers p {
    font-size: 18px;
    color: #060828;
    font-weight: 400;
    line-height: 30px
}

.Company_offers_section .technology_section {
    margin-top: 40px
}

.technology_section .visit_title {
    margin-bottom: 15px
}

.visit_title {
    font-size: 30px;
    font-weight: 700;
    color: #00c5de;
    line-height: 62px
}

.visit_section .main_btn {
    font-weight: 400
}

.visit_section p {
    margin-bottom: 35px
}

.Company_offers .carousel-indicators li {
    background-color: #000
}

.color_circle_box {
    height: 38px;
    width: 38px;
    border-radius: 50px;
    margin: 0 auto
}

.color_circle_box.balck_bg {
    background-color: #020102
}

.color_circle_box.white_bg {
    background-color: #fff
}

.color_circle_box.green_bg {
    background-color: #2ecc71
}

.color_circle_box.skyblue_bg {
    background-color: #67c4ff
}

.color_circle_box.purple_bg {
    background-color: #473b99
}

.color_code_content {
    font-size: 10px;
    margin-top: 15px;
    line-height: 10px
}

.color_code_section {
    margin-right: 20px
}

.color_code_section:last-child {
    margin-right: 0
}

.value_added {
    padding: 80px 0
}

.service_img_circle {
    height: 160px;
    width: 160px;
    border: 1px solid #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin: 0 auto;
    transition: all .5s
}

.service_img_circle img {
    transition: all .5s
}

.service_title {
    font-size: 16px;
    color: #060828;
    line-height: 22px;
    margin-top: 15px
}

.service_img_circle:hover {
    box-shadow: 0 -5px 10px 3px #f2f2f2;
    border: 2px solid #00c5de;
    transition: all .3s
}

.service_img_circle:hover img {
    transition: all .3s;
    margin-top: -10px
}

.blog_sectoin.grey_bg {
    background-color: #f6f6f6
}

.Consult_expert {
    background-color: #f6f6f6;
    padding: 80px 0
}

.Consult_expert .main_btn {
    padding: 0 40px
}

.Consult_expert .contact_heading {
    margin-bottom: 40px
}

.expertise_box {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .16);
    border-radius: 4px;
    padding: 30px;
    width: 100%;
    float: left;
    min-height: 345px;
    margin-top: 30px;
    transition: all .3s
}

.expertise_box:hover {
    transition: all .3s;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, .1)
}

.expertise_box_img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto
}

.expertise_box_img::after,
.expertise_box_img::before {
    box-sizing: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%
}

.spin {
    width: 150px;
    height: 150px
}

.expertise_box:hover .spin {
    color: #00c5de
}

.spin::before {
    border: 2px solid transparent
}

.expertise_box:hover .spin::before {
    border-top-color: #00c5de;
    border-right-color: #00c5de;
    border-bottom-color: #00c5de;
    transition: border-top-color .15s linear, border-right-color .15s linear .1s, border-bottom-color .15s linear .2s
}

.spin::after {
    border: 0 solid transparent
}

.expertise_box:hover .spin::after {
    border-top: 2px solid #00c5de;
    border-left-width: 2px;
    border-right-width: 2px;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    transition: border-left-width 0s linear .35s, -webkit-transform .4s linear 0s;
    transition: transform .4s linear 0s, border-left-width 0s linear .35s;
    transition: transform .4s linear 0s, border-left-width 0s linear .35s, -webkit-transform .4s linear 0s
}

.circle::after,
.circle::before {
    border-radius: 100%
}

.expertise_title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #060828
}

.expertise_Text {
    font-size: 16px;
    line-height: 1.67;
    padding-top: 18px;
    text-align: center;
    color: #060828
}

.our_platform_box {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.our_platforms_row:hover>.our_platform_box {
    opacity: .2
}

.our_platforms_row .our_platform_box:hover {
    opacity: 1
}

.app_detail_box {
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    padding: 34px 38px;
    font-size: 22px;
    color: #060828;
    line-height: normal;
    cursor: context-menu;
    transition: .3s;
    margin-top: 30px
}

.app_detail_box:hover {
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, .1)
}

.app_detail_box img {
    margin: 5px 25px 0 0
}

.app_detail_box span {
    color: #00c5de;
    font-weight: 600
}

.app_development_title {
    font-size: 22px;
    color: #00c5de
}

.app_development {
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
    padding: 38px 45px;
    margin-top: 30px;
    transition: .3s
}

.app_development:hover {
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, .1)
}

.app_development p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.67;
    padding-top: 10px;
    color: #060828
}

.app_feature_box {
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
    background-color: #fff;
    padding: 43px 35px;
    margin-top: 30px;
    transition: .3s
}

.app_feature_box:hover {
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, .1)
}

.app_feature_img {
    text-align: center
}

.app_feature_title {
    font-size: 24px;
    font-weight: 700;
    color: #00c5de;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 5px
}

.app_feature_text {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    line-height: 1.67;
    color: #060828
}

.our_iphone_development_project {
    padding: 80px 0;
    background-color: #f6f6f6
}

.our_iphone_development_project .Company_offers_section {
    margin-top: 0
}

.development_project_box {
    border-radius: 5px;
    margin-bottom: 20px;
    transition: .3s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}

.development_project_box img {
    width: 100%
}

.development_project_hover_box {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    background: #000;
    color: #fff;
    font-family: sans-serif;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility 0s, opacity .3s linear;
    transition: visibility 0s, opacity .3s linear;
    border-radius: 5px
}

.title_development_project {
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%
}

.development_project_box:hover {
    box-shadow: 0 0 8px 6px #efefef
}

.development_project_box:hover .development_project_hover_box {
    visibility: visible;
    opacity: .7
}

.title_line {
    width: auto;
    float: left;
    font-size: 25px;
    font-weight: 600;
    text-align: center
}

.development_content {
    font-size: 24px;
    font-weight: 400
}

.title_project .title_line::after {
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    background: #fff;
    margin: 15px 0;
    margin-left: calc(50% - 50px)
}

.project_box_center {
    place-content: center
}

.blog_section_about {
    padding: 80px 0
}

.blog_top_section {
    padding: 60px 0px 30px;
}

.blog_top_section .blog-list {
    display: flex;
    margin-bottom: 12px;
}

.blog_top_section .blog-list .blog-list-img {
    /* margin-right: 15px;
    min-width: 80px;
    min-height: 80px;
    max-width: 80px; 
    max-height: 80px;
    overflow: hidden; */
    width: 40% !important;
}

.blog_top_section .blog-list .blog-list-img img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
}

.blog-top-img {
    margin-bottom: 24px;
}

.blog-top-img img {
    /* height: 450px; */
    object-fit: cover;
}

.blog_top_section .blog_top_content h2,
.blog_top_section .blog_top_content h2 a {
    font-size: 34px;
    line-height: 44px;
    font-weight: 700;
    color: #000;
    /* width: calc(100% - 120px);
    float: right; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
}

.blog-list-content {
    width: calc(100% - 80px);
    margin-left: 20px;
}

.blog-list-content h4,
.blog-list-content h4 a {
    font-size: 17px;
    line-height: 23px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    color: #000;
}

.blog-list-content .last-child-p {
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.4);
}

.blog-list-content .last-child-p a {
    color: #00C5DE;
    margin-left: 5px;
    text-decoration: none;
}

.blog-list-content-box {
    width: 100%;
    float: left;
    font-size: 14px;
    margin-top: 5px;
}

.blog-list-content-box .bloger-name {
    max-width: 150px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
    color: rgba(0, 0, 0, 0.4);
}

.blog-list-content-box .bloger-name img {
    float: left;
    padding-top: 1px;
    margin-right: 5px;
}

.blog-list-content-box .blog-catagory {
    width: calc(100% - 150px);
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #00c5de;
}

.blog-list-content-box .blog-catagory a {
    color: #00c5de;
    text-decoration: none;
}

.blog_top_section .blog_top_content p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog_top_section .blog_top_content .last-child-p {
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.4);
}

.blog_top_section .blog_top_content .last-child-p span {
    max-width: 150px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog_top_section .blog_top_content .last-child-p a {
    color: #00C5DE;
    margin-left: 5px;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 150px);
}

.blog_top_section .blog_top_content .last-child-p img,
.blog-list-content .last-child-p img {
    margin-right: 5px;
}

.blog_section_about .blog_title {
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 15px;
    height: 50px;
    padding: 0
}

.blog_section_about .blog_title a {
    color: #000
}

.popular-section {
    background-color: #F4F4F4;
    padding: 60px 0px;
}

.popular-section .popular-title h2 {
    font-size: 40px;
    line-height: 62px;
    font-weight: 700;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.popular-section .popular-title h2::after {
    content: '';
    display: block;
    width: 763px;
    height: 4px;
    background: #00c5de;
    margin: -30px 0 20px 0;
    margin-left: calc(50% - 208px);
}

.popular-section .popular-title {
    margin-bottom: 57px;
}

.popular-section .single-popular .popular-content {
    margin-right: 54px;
}

.popular-section .single-popular .popular-content p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #000;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.popular-section .single-popular .popular-content h5 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.popular-section .single-popular .popular-content .main_btn {
    padding: 10px 30px;
    text-decoration: none;
    margin-top: 20px;
    display: inline-block;
}

.popular-section .single-popular .popilar-img {
    min-width: 500px;
    /* min-height: 305px; */
}

.popular-section .single-popular .popilar-img img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
}

.popular-section .single-popular {
    background-color: #fff;
    padding: 25px;
    margin-bottom: 16px;
}

.tranding-section {
    padding: 60px 0px;
}

.tranding-section .tranding-main h2 {
    font-size: 40px;
    line-height: 62px;
    font-weight: 700;
    margin-bottom: 0px;
    text-transform: uppercase;
    margin-bottom: 36px;
}

.tranding-section .tranding-main h2::after {
    content: '';
    display: block;
    width: 519px;
    height: 4px;
    background: #00c5de;
    margin: -32px 0 20px 0;
    margin-left: calc(50% - 125px);
}

.tranding-section .tranding-main h3 {
    font-size: 30px;
    line-height: 62px;
    font-weight: 700;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.tranding-section .tranding-main .tranding-list .tranding-list-img {
    min-width: 175px;
    /* height: 140px; */
    margin-right: 17px;
    padding-top: 10px;
}

.tranding-section .tranding-main .tranding-list {
    margin-bottom: 22px;
    padding-right: 28px;
}

.tranding-section .tranding-main .tranding-list .tranding-list-img img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}

.tranding-list .tranding-list-content a h5 {
    font-size: 22px;
    line-height: 23px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tranding-list-content {
    overflow: hidden;
}

.tranding-list .tranding-list-content a {
    color: #000;
    text-decoration: none;
}

.tranding-list .tranding-list-content p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #000;
    margin-bottom: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tranding-list .tranding-list-content .last-child-p {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.4);
}

.tranding-list .tranding-list-content .last-child-p .all-title {
    max-width: 150px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tranding-list .tranding-list-content .last-child-p img {
    margin-right: 5px;
}

.tranding-list .tranding-list-content .last-child-p .all-cat {

    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 150px);
}

.tranding-list .tranding-list-content .last-child-p a {
    color: #00C5DE;
    margin-left: 5px;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
}

.tranding-section .categories-main h3 {
    font-size: 30px;
    font-weight: 700;
    line-height: 62px;
    color: #000;
    text-transform: uppercase;
    padding-left: 40px;
}

.tranding-section .categories-main h3::after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background: #00c5de;
    margin: -33px 0 20px 0;
    margin-left: calc(50% - -55px);
}

.tranding-section .categories-main .categories-list {
    border-left: 1px solid #ECECEC;
    padding-left: 40px;
    margin-top: 30px;
}

.categories-main .categories-list .single-categories {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 12px;
}

.categories-list a:last-of-type .single-categories {
    border-bottom: 0px;
}

.categories-list .single-categories .cate-name label {
    font-size: 18px;
    line-height: 16px;
    font-weight: 400;
    color: #000;
    cursor: pointer;
}

.categories-list a {
    text-decoration: none;
}

.categories-list .single-categories .categories-number {
    width: 30px;
    height: 30px;
    background: #00C5DE;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}

.categories-list .single-categories .categories-number span {
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
}

.explore-section {
    padding: 60px 0px;
    background-color: #F4F4F4;
}

.explore-section .explore-title {
    margin-bottom: 47px;
}

.explore-section .explore-title h2 {
    font-size: 40px;
    line-height: 62px;
    font-weight: 700;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.explore-section .explore-title h2::after {
    content: '';
    display: block;
    width: 580px;
    height: 4px;
    background: #00c5de;
    margin: -30px 0 20px 0;
    margin-left: calc(50% - 26px);
}

.explore-section .explore-title p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: rgba(0, 0, 0, .6);
    margin-bottom: 0px;
}

.explore-section a {
    text-decoration: none;
}

.explore-section .explore-list {
    background-color: #fff;
    height: 62px;
    border: 1px solid #00C5DE;
    border-radius: 8px;
    padding: 16px 14px;
    margin-bottom: 40px;
}

.explore-section .explore-list .explore-img {
    margin-right: 13px;
}

.explore-section .explore-list .explore-img img {
    height: 30px;
    width: 30px;
    object-fit: cover;
}

.explore-section .explore-list .explore-text p {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    line-height: 27px;
}

.explore-section .explore-list .arrow-right {
    margin-left: auto;
}

.best-thing-sec {
    background-image: url(assets/images/best-thing-bg.png);
    width: 100% !important;
    background-size: cover;
    background-repeat: no-repeat;
}

.best-thing-sec .thing-text {
    font-size: 38px;
    color: #fff;
    line-height: 50px;
    font-weight: 600;
    margin-bottom: 50px;
}

.best-thing-sec p {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 10px;
}


/* footer ayushi */

.footer-sec-black {
    padding: 60px 0px;
    background-color: #000;
}

.footer-sec-black h3 {
    font-size: 20px;
    line-height: 36px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 20px;
}

.footer-sec-black a {
    font-size: 16px;
    color: #999999;
    display: block;
    line-height: 38px;
    text-decoration: none;
}

.footer-sec-black .border-right {
    border-right: 1px solid #383838;
}

.padding-left-footer {
    padding-left: 35px;
}

.blog_section_about .blog_title a:hover {
    color: #00c5de;
    text-decoration: none
}

.blog_section_about .blog_box p {
    padding-bottom: 0;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: #060828;
    padding-top: 20px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 140px
}

.blog_section_about .blog_box {
    margin-top: 0;
    margin-bottom: 40px
}

.continue_reading {
    font-size: 18px;
    color: #00c5de;
    font-weight: 600
}

.continue_reading:hover {
    color: #00c5de;
    text-decoration: none
}

.blog_section_about .blog_img {
    max-height: 230px;
    overflow: hidden
}

.blog_section_about .pagination {
    display: inline-block
}

.blog_section_about .pagination li {
    color: #00c5de;
    float: left;
    padding: 8px 10px;
    text-decoration: none;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer
}

.blog_section_about .pagination ul {
    display: block;
    padding: 0;
    margin: 0
}

.blog_section_about .pagination a {
    color: #00c5de
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: #00c5de
}

.Previous_anchor {
    margin-right: 30px
}

.next_anchor {
    margin-left: 30px
}

.blog_section_about .pagination li.current {
    color: #3e4058;
    background: 0 0
}

.add-on .input-group-btn>.btn {
    height: 58px;
    width: 120px;
    background-color: #0198AB;
    border-radius: 0px 50px 50px 0px;
    margin-top: 5px;
    cursor: pointer;
    position: absolute;
    margin-left: -125px;
    z-index: 3;
    font-size: 18px;
    color: #fff;
    top: -5px;
    right: 0px;
}

.add-on .form-control:focus,
.add-on .input-group-btn>.btn:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border: 1px solid #d7d7d7
}

.add-on .input-group-btn {
    position: relative
}

.add-on input {
    border: 1px solid #d7d7d7;
    width: 100%;
    min-width: 470px;
    border-radius: 50px !important;
    min-height: 58px;
    padding-left: 20px;
    font-size: 18px;
    position: relative;
    padding-right: 130px;
    outline: 0
}

.add-on {
    justify-content: center;
    margin-top: 16px;
    float: left
}

.subscribe_content {
    font-size: 22px;
    line-height: 30px;
    color: #fff;
}

.bloger_name_date img {
    margin-right: 5px
}

.Previous_button {
    position: relative;
    cursor: pointer
}

.Previous_button::after,
.Previous_button::before {
    box-sizing: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%
}

.draw {
    transition: color .25s
}

.draw::after,
.draw::before {
    border: 1px solid transparent;
    width: 0;
    height: 0
}

.draw::before {
    top: 0;
    left: 0
}

.draw::after {
    bottom: 0;
    right: 0
}

.draw:hover {
    color: #00c5de
}

.draw:hover::after,
.draw:hover::before {
    width: 100%;
    height: 100%
}

.draw:hover::before {
    border-top-color: #00c5de;
    border-right-color: #00c5de;
    transition: width .25s ease-out, height .25s ease-out .25s
}

.draw:hover::after {
    border-bottom-color: #00c5de;
    border-left-color: #00c5de;
    transition: border-color 0s ease-out .5s, width .25s ease-out .5s, height .25s ease-out .75s
}

.meet:hover {
    color: #00c5de
}

.meet::after {
    top: 0;
    left: 0
}

.meet:hover::before {
    border-top-color: #00c5de;
    border-right-color: #00c5de
}

.meet:hover::after {
    border-bottom-color: #00c5de;
    border-left-color: #00c5de;
    transition: height .25s ease-out, width .25s ease-out .25s
}

.social-blog-btn .sb-circles-dark .sb-wrapper {
    background: none;
    border: 0px;
}

.social-blog-btn .sb-circles-dark .sb-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #1ccbe1; */
    height: 50px !important;
    width: 50px !important;
    border-radius: 50%;
    padding-top: 5px;
    transition: all .5s;
    margin-right: 20px;
}

.social-blog-btn .sb-circles-dark .sb-wrapper.sb-whatsapp {
    background: #25D366;
}

.social-blog-btn .sb-circles-dark .sb-wrapper.sb-facebook {
    background: #4267B2;
}

.social-blog-btn .sb-circles-dark .sb-wrapper.sb-twitter {
    background: #1DA1F2;
}

.social-blog-btn .sb-circles-dark .sb-wrapper.sb-linkedin {
    background: #0077b5;
}

.social-blog-btn .sb-circles-dark .sb-wrapper .sb-content .sb-icon {
    font-size: 22px;
}

.social-blog-btn .sb-circles-dark .sb-wrapper:hover {
    margin-top: -10px;
    /* background-color: #00c5de;
    border: 1px solid #00c5de; */
    -webkit-animation: heartbeat .2s infinite;
    animation: heartbeat .2s infinite;
}

.social-blog-btn .sb-circles-dark {
    display: flex;
}

.blog_inner_section {
    padding: 120px 0 80px 0
}

.blog_inner_section ul li {
    text-align: left
}

.blog_inner_section .blog-decs img {
    max-width: 100%;
    height: auto;
}

.blog_heading {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px
}

.blog_inner_section .bloger_name_date {
    margin-top: 15px;
    font-size: 22px
}

.blog_inner_section .bloger_name_date.home_small_text {
    font-size: 18px;
    margin-bottom: 25px
}

.blog_social_icon_section a img {
    height: 47px !important;
    width: 47px !important;
    margin-right: 11px;
}

.modal_expert.border_top_none {
    border-top: none
}

.top_img img {
    width: 100%;
    margin: 45px 0 40px 0
}

.blog_inner_section p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 30px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 10px;
}

.blog_inner_section p img {
    margin-right: 8px;
}

.blog_inner_section p a {
    color: #00C5DE;
    text-decoration: none;
    margin-left: 5px;
}

.blog_inner_section .share-social-icon img {
    width: 24px;
    height: 24px;
}

.blog_inner_section .share-social-icon h4 {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    width: 100%;
    max-width: 230px;
    margin-bottom: 0px;
}

.inner_blog_sub_title {
    font-size: 22px;
    color: #060828;
    line-height: 22px;
    margin: 20px 0
}

.inner_blog_img img {
    margin-bottom: 35px;
    margin-top: 20px;
    max-height: 600px;
    overflow: hidden
}

.blog_grey_bg {
    background-color: #f6f6f6;
    padding: 100px 0
}

.blog_grey_bg .blog_box {
    margin-bottom: 0;
    background-color: #fff
}

.faq_section .card {
    margin-bottom: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1)
}

.faq_section .card-header {
    background-color: #fff;
    min-height: 60px;
    display: flex;
    align-items: center
}

.faq_section .card-link {
    color: #00c5de;
    font-size: 18px;
    font-weight: 600
}

#portfolio-filter {
    float: left;
    font-size: 16px;
    list-style: none;
    padding: 25px 0 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

#portfolio-filter li {
    float: left;
    line-height: 16px;
    margin: 0;
    min-width: 150px;
    margin-right: 20px
}

#portfolio-filter li:last-child {
    border-right: 0;
    margin-right: 0;
    padding-right: 0
}

#portfolio-filter a {
    color: #00c5df;
    text-decoration: none;
    border: 0;
    background: #fff;
    border-radius: 50px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    text-transform: uppercase;
    text-align: center;
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 45px;
    border: 1px solid #ececec
}

#portfolio-filter a:hover,
#portfolio-filter li.current a {
    text-decoration: none;
    background: #fff;
    color: #00c5de
}

#portfolio-filter li.current a {
    font-weight: 600;
    background: #00c5df;
    color: #fff;
    border: 1px solid #00c5df
}

nav#porfolio-nav {
    width: 100%;
    position: relative;
    float: left;
    padding-bottom: 50px
}

nav#porfolio-nav ul {
    padding: 0;
    margin: 0 auto
}

nav#porfolio-nav li {
    display: inline;
    float: left
}

nav#porfolio-nav li a {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box
}

nav#porfolio-nav li:last-child a {
    border-right: 0
}

.errorMessage {
    font-size: 14px;
    color: red !important;
    font-weight: 500 !important
}

@media (min-width:992px) {
    .dropdown:hover>.dropdown-menu {
        display: block
    }
}

@media (max-width:1200px) {

    .line,
    .main_title {
        font-size: 40px;
        line-height: 30px
    }

    .main_title .line::after {
        margin: 20px 0 10px 0;
        margin-left: calc(50% - 50px)
    }

    .navbar-brand,
    .navbar-brand img {
        transition: .5s;
        max-width: 250px
    }

    .top_header .nav-link {
        padding-right: 10px !important
    }

    .submenu_list_title {
        font-size: 16px
    }

    .slider_text_title span {
        font-size: 45px;
        padding: 0
    }

    .slider_text {
        font-size: 18px;
        line-height: 25px;
        margin: 20px 0
    }

    .carousel-caption .main_btn {
        font-size: 18px
    }

    .slider_small_title {
        font-size: 20px;
        line-height: 30px
    }

    .MS-controls button {
        margin-top: -270px
    }

    .services_section {
        padding: 60px 0
    }

    .slider_box {
        padding: 25px 5px;
    }

    .services_slider_title {
        font-size: 20px;
        padding-top: 16px;
        line-height: 20px;
    }

    .services_slider_text {
        font-size: 16px;
        line-height: 20px;
    }

    .number_images_row {
        margin-top: -6px
    }

    .number_images_row img {
        max-width: 100px;
        width: 100%
    }

    .number_images .number_images_row:first-child img {
        max-width: 780px
    }

    .number_images_row h5 {
        font-size: 18px;
        margin-bottom: 0
    }

    .number_images_row p {
        font-size: 14px
    }

    .leftRs,
    .rightRs {
        margin-top: 95px
    }

    .portfolio_section {
        padding: 60px 0
    }

    .partner_section {
        padding: 60px 0
    }

    .expert_section {
        padding: 60px 0
    }

    .expert_text {
        font-size: 25px;
        line-height: 35px
    }

    .testimonials_section {
        padding: 60px 0
    }

    .testimonials_section .testimonial {
        font-size: 18px;
        line-height: 28px;
        padding: 20px
    }

    .testimonials_section .media img {
        width: 60px;
        height: 60px
    }

    .testimonials_section .media .media-left {
        margin-right: 10px
    }

    .testimonials_section .overview b {
        font-size: 18px;
        line-height: 20px;
        font-weight: 500
    }

    .testimonials_section .overview .details {
        font-size: 14px;
        line-height: 15px
    }

    .teams_section {
        padding: 60px 0
    }

    .client_section {
        padding: 60px 0
    }

    .client_logo {
        max-width: 100%;
        height: 80px
    }

    .blog_sectoin {
        padding: 60px 0
    }

    .blog_title {
        font-size: 20px;
        line-height: 25px
    }

    .client_logo img {
        max-width: 130px
    }

    .footer_section {
        padding: 40px 0 0 0
    }

    .footer_copyright {
        margin-top: 80px
    }

    .footer_title {
        margin-top: 30px;
        text-align: center
    }

    .about_we {
        padding: 60px 0
    }

    .about_we p {
        margin-bottom: 20px
    }

    .right_timeline {
        padding: 45px 15px 50px 50px
    }

    .left_timeline {
        padding: 45px 50px 50px 15px
    }

    .timeline_section {
        padding: 60px 0
    }

    .timeline_title {
        font-size: 30px;
        font-weight: 500
    }

    .about_title {
        font-size: 30px;
        font-weight: 500;
        line-height: 30px;
        padding-bottom: 10px
    }

    .about_large_text {
        font-size: 20px;
        line-height: 26px
    }

    .about_text {
        font-size: 16px;
        line-height: 28px
    }

    .about_company {
        padding: 60px 0
    }

    .modal_box_right {
        padding: 30px
    }

    .modal_box_space {
        margin-top: 50px
    }

    .modal_expert_text {
        font-size: 25px;
        font-weight: 500;
        padding: 0 25px 25px 25px
    }

    .modal_expert {
        padding: 40px 0
    }

    .find-location .location-details p {
        width: 80%
    }

    .work_culture_img img {
        max-width: 40px
    }

    .work_culture_img {
        width: 60px
    }

    .work_culture_inner_content {
        width: calc(100% - 60px)
    }

    .work_culture_img img {
        max-width: 45px
    }

    .career_about {
        padding: 60px 0
    }

    .work_culture {
        padding: 60px 0
    }

    .life_section {
        padding: 60px 0
    }

    .employee_speaks {
        padding: 60px 0
    }

    .current_openings {
        padding: 60px 0
    }

    .range_services {
        padding: 60px 0
    }

    .services_about {
        padding: 60px 0
    }

    .top_notch {
        padding: 60px 0 50px
    }

    .wordPress_development_about {
        padding: 60px 0
    }

    .Company_offers {
        padding: 60px 0
    }

    .service_img_circle img {
        height: 65px;
        width: 65px
    }

    .Company_offers_section .carousel-item {
        min-height: 555px
    }

    .our_platforms {
        padding: 60px 0
    }

    .value_added {
        padding: 60px 0
    }

    .value_added_content {
        margin-top: 25px
    }

    .service_img_circle {
        height: 120px;
        width: 120px
    }

    .Consult_expert {
        padding: 60px 0
    }

    .app_detail_box {
        padding: 35px 25px;
        font-size: 20px
    }

    .app_detail_box img {
        margin: 3px 20px 0 0
    }

    .modal_factor_section {
        padding: 60px 0
    }

    .app_development {
        padding: 25px
    }

    .app_feature_box {
        padding: 30px 25px
    }

    .app_feature_title {
        font-weight: 500
    }

    .our_iphone_development_project {
        padding: 60px 0
    }

    .our_iphone_development_project .Company_offers_section {
        margin-top: 0
    }

    .hire_developer .app_feature_box {
        padding: 30px 10px
    }

    .app_feature_title {
        font-size: 20px
    }

    .blog_section_about {
        padding: 60px 0
    }

    .blog_heading br {
        display: none
    }

    .inner_blog_img img {
        width: 100%
    }

    .blog_heading {
        font-size: 30px;
        line-height: 30px
    }

    .blog_grey_bg {
        padding: 60px 0
    }

    #portfolio-filter li {
        max-width: 130px;
        width: 100%;
        min-width: unset !important;
        margin-right: 15px
    }

    .popular-section .popular-title h2::after {
        width: 550px;
        margin-left: calc(50% - 85px);
    }

    .tranding-section .tranding-main h2::after {
        width: 380px;
        margin-left: calc(50% - 50px);
    }

    .tranding-section .categories-main h3::after {
        width: 50px;
        margin-left: calc(50% - -74px);
    }

    .explore-section .explore-title h2::after {
        width: 434px;
        margin-left: calc(50% - -31px);
    }
}

@media (max-width:1024px) {
    .MS-controls button {
        margin-top: -260px
    }
}

@media (min-width:1024px) {
    .carousel-item {
        height: calc(100% - 80px)
    }
}

@media (max-width:992px) {

    .line,
    .main_title {
        font-size: 35px;
        line-height: 40px;
    }

    .main_btn {
        font-size: 16px;
        font-weight: 500;
        height: 40px;
        padding: 0 20px;
        line-height: 18px;
        margin-top: 10px;
    }

    .sub_title {
        margin-bottom: 10px;
        font-size: 18px
    }

    .submenu_list li a {
        width: 100%;
        display: inline-block
    }

    .about_section p {
        font-size: 16px;
        line-height: 25px
    }

    .navbar,
    .top_header {
        min-height: 50px
    }

    .mobile_menu_arrow::after {
        border: none;
        background: #fff0 url(assets/images/chevron-down.svg);
        width: 15px;
        height: 10px;
        background-position: 50% 50%;
        margin-top: 8px;
        margin-left: 5px;
        float: right;
        background-size: 18px
    }

    .mobile_menu_arrow {
        display: block;
        padding-top: 10px;
        float: right;
        cursor: pointer
    }

    .menu_arrow::after {
        display: none
    }

    .navbar {
        padding: 10px 0
    }

    .top_header .main_btn {
        margin-top: 10px
    }

    .menu_contact_btn .main_btn {
        width: 100%
    }

    .main_title .line::after {
        margin-left: calc(50% - 50px)
    }

    .nav-item {
        height: auto;
        display: block
    }

    .dropdown-menu {
        max-height: 50vh;
        overflow-y: auto;
        overflow-x: hidden
    }

    .top_header .nav-link {
        width: 90%;
        float: left;
        padding-bottom: 0
    }

    .submenu_list_title {
        font-size: 18px
    }

    .slider_main {
        margin-top: 64px
    }

    .slider_text_title span {
        font-size: 26px;
        font-weight: 500
    }

    .slider_small_title {
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        padding: 15px 0 0 0
    }

    .slider_text {
        font-size: 16px;
        line-height: 20px;
        margin: 15px 0
    }

    .carousel-caption .main_btn {
        font-size: 16px;
        font-weight: 500
    }

    .number_images .number_images_row:first-child img {
        max-width: 580px;
        margin-left: 8px
    }

    .slider_logo img {
        max-width: 100px;
        margin-left: 8px
    }

    .number_images_row h5 {
        font-size: 16px
    }

    .number_images_row p {
        font-size: 12px
    }

    .slider_background {
        padding-top: 20px
    }

    .about_section {
        padding: 40px 0
    }

    .services_section {
        padding: 40px 0
    }

    .ResSlid0 .item {
        width: 33.33% !important
    }

    .resCarousel {
        padding-bottom: 30px
    }

    .services_slider_text {
        font-size: 14px;
    }

    .our_services_icons_fix {
        max-width: 80px
    }

    .slider_box {
        min-height: 241px
    }

    .our-portfolio {
        padding: 40px 0
    }

    .portfolio_section {
        padding: 40px 0
    }

    .portfolio_box_title {
        font-size: 16px;
        font-weight: 500
    }

    .portfolio_box {
        margin-bottom: 20px
    }

    .expert_section {
        padding: 40px 0
    }

    .expert_text {
        font-weight: 500;
        text-align: center;
        margin-bottom: 20px
    }

    .partner_section {
        padding: 40px 0
    }

    .testimonials_section {
        padding: 40px 0
    }

    .testimonials_section .carousel .testimonial {
        font-size: 16px;
        line-height: 25px
    }

    .testimonials_section .testimonial {
        font-size: 14px;
        line-height: 25px
    }

    .testimonials_video_slider_main .leftRs,
    .testimonials_video_slider_main .rightRs {
        margin-top: 50px
    }

    .teams_section {
        padding: 40px 0
    }

    .team_name {
        font-size: 16px;
        font-weight: 500;
        min-height: 60px;
        line-height: 20px
    }

    .team_name span {
        font-size: 14px;
        font-weight: 400
    }

    .teams_box {
        margin: 20px 0
    }

    .client_section {
        padding: 40px 0
    }

    .client_logo {
        height: 80px
    }

    .client_logo img {
        max-width: 100px
    }

    .blog_sectoin {
        padding: 40px 0
    }

    .blog_title {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500
    }

    .bloger_name_date {
        font-size: 16px;
        margin-bottom: 5px
    }

    .blog_read_more {
        padding-top: 10px
    }

    .blog_read_more a {
        font-size: 18px
    }

    .blog_read_more a svg {
        transition: .5s;
        width: 18px;
        margin-top: -2px
    }

    .blog_box {
        margin-bottom: 30px
    }

    /* blog design */
    .blog_top_section {
        padding: 30px 0px 30px;
    }

    .blog_top_section .blog_top_content h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .blog-top-img {
        margin-bottom: 15px;
    }

    .blog-top-img img {
        /* height: 240px; */
        height: auto;
    }

    .blog_top_section .blog_top_content p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
    }

    .blog-list-content .last-child-p,
    .blog_top_section .blog_top_content .last-child-p {
        font-size: 13px;
    }

    .blog-list-content h4 {
        font-size: 16px;
        line-height: 20px;
    }

    .blog-list .blog-list-img {
        margin-right: 10px;
    }

    .popular-section,
    .tranding-section,
    .explore-section,
    .best-thing-sec,
    .footer-sec-black {
        padding: 30px 0px;
    }

    .popular-section .popular-title h2::after {
        width: 410px;
        margin: -19px 0 20px 0;
        margin-left: calc(50% - 67px);
        height: 3px;
    }

    .popular-section .popular-title h2,
    .tranding-section .tranding-main h2,
    .tranding-section .categories-main h3,
    .explore-section .explore-title h2 {
        font-size: 28px;
        line-height: 32px;
    }

    .popular-section .popular-title {
        margin-bottom: 30px;
    }

    .popular-section .single-popular .popilar-img {
        min-width: 270px;
        min-height: auto;
    }

    .popular-section .single-popular .popular-content {
        margin-right: 25px;
    }

    .popular-section .single-popular .popular-content h5 {
        font-size: 22px;
        line-height: 28px;
    }

    .popular-section .single-popular .popular-content p {
        font-size: 14px;
        line-height: 20px;
    }

    .tranding-section .tranding-main .tranding-list .tranding-list-img {
        min-width: 100px;
        height: 100px;
        margin-right: 10px;
    }

    .tranding-list .tranding-list-content p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .tranding-list .tranding-list-content a h5 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 3px;
    }

    .tranding-section .tranding-main .tranding-list {
        padding-right: 0px;
    }

    .tranding-list .tranding-list-content .last-child-p {
        font-size: 13px;
    }

    .tranding-section .categories-main .categories-list {
        padding-left: 20px;
    }

    .categories-list .single-categories .categories-number {
        width: 25px;
        height: 25px;
        margin-bottom: 10px;
    }

    .categories-main .categories-list .single-categories {
        margin-bottom: 10px;
    }

    .categories-list .single-categories .cate-name label {
        font-size: 16px;
    }

    .tranding-section .tranding-main h2::after {
        width: 200px;
        height: 3px;
        margin-left: calc(50% - -5px) !important;
        margin: -17px 0 20px 0;
    }

    .tranding-section .categories-main h3 {
        padding-left: 20px;
    }

    .tranding-section .categories-main h3::after {
        width: 50px;
        height: 3px;
        margin: -16px 0 20px 0;
        margin-left: calc(50% - -76px);
    }

    .explore-section .explore-title h2::after {
        width: 330px;
        height: 3px;
        margin: -16px 0 20px 0;
        margin-left: calc(50% - -14px);
    }

    .explore-section .explore-title p {
        font-size: 14px;
        line-height: 20px;
    }

    .explore-section .explore-title {
        margin-bottom: 20px;
    }

    .explore-section .explore-list {
        margin-bottom: 20px;
        padding: 12px 10px;
        height: 50px;
    }

    .explore-section .explore-list .explore-text p {
        font-size: 13px;
        line-height: 17px;
    }

    .explore-section .explore-list .explore-img {
        margin-right: 8px;
    }

    .explore-section .explore-list .explore-img img {
        height: 21px;
        width: 21px;
        object-fit: contain;
    }

    .arrow-right img {
        width: 20px;
        height: 20px;
    }

    .best-thing-sec p {
        margin-bottom: 5px;
    }

    .best-thing-sec .thing-text {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
    }

    .border-right {
        border-right: 0px !important;
    }

    .padding-left-footer {
        padding-left: 15px;
        margin-bottom: 20px;
    }

    .footer-sec-black h3 {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .footer-sec-black a {
        font-size: 14px;
        line-height: 26px;
    }

    .footer_section {
        padding: 20px 0 0 0
    }

    .footer_title {
        margin-top: 30px;
        font-size: 30px;
        font-weight: 500;
        text-align: center
    }

    .footer_copyright {
        margin-top: 40px
    }

    .footer_section input,
    .footer_section select,
    .footer_section textarea {
        min-height: 50px;
        padding-left: 15px;
        font-size: 16px
    }

    .footer_section textarea {
        padding-top: 15px
    }

    .checkbox {
        font-size: 16px;
        padding-left: 35px
    }

    .contact_us {
        padding-top: 15px;
        padding-bottom: 20px
    }

    select {
        background: url(assets/images/caret-down.svg) transparent no-repeat calc(100% - 0px) !important
    }

    .bottom_footer {
        height: 50px;
        font-size: 16px
    }

    .about_us_page {
        margin-top: 55px;
        height: 300px
    }

    .about_we {
        padding: 40px 0
    }

    .banner_title {
        font-size: 40px;
        font-weight: 600
    }

    .banner_sub_title {
        font-size: 18px
    }

    .about_we p {
        font-size: 16px
    }

    .right_timeline {
        padding: 25px 15px 25px 25px
    }

    .left_timeline {
        padding: 25px 25px 25px 15px
    }

    .timeline_title {
        font-size: 26px;
        font-weight: 500
    }

    .left_timeline p,
    .right_timeline p {
        font-size: 16px;
        line-height: 25px
    }

    .timeline_years {
        width: 150px;
        height: 50px;
        font-size: 20px
    }

    .timeline_section {
        padding: 40px 0
    }

    .timeline_logo {
        margin-bottom: 40px
    }

    .about_company {
        padding: 40px 0
    }

    .about_title {
        font-size: 28px;
        font-weight: 500;
        line-height: 23px;
        padding: 10px 0;
        text-align: center !important
    }

    .about_large_text {
        font-size: 18px;
        font-weight: 400;
        line-height: 23px;
        text-align: center !important
    }

    .about_text {
        font-size: 16px;
        line-height: 28px
    }

    .modal_factor_section {
        padding: 40px 0
    }

    .find-location .location-details p {
        width: 80%
    }

    .find-location .location-details {
        min-height: 249px
    }

    .work_culture_inner_content {
        width: calc(100% - 80px)
    }

    .work_culture_img img {
        max-width: 70px
    }

    .career_about {
        padding: 40px 0
    }

    .work_culture {
        padding: 40px 0
    }

    .life_section {
        padding: 40px 0
    }

    .employee_speaks {
        padding: 40px 0
    }

    .current_openings {
        padding: 40px 0
    }

    .contact_deatail {
        padding: 40px 0
    }

    .current_openings .modal-dialog {
        width: 100%;
        max-width: 98%
    }

    .current_openings .modal {
        padding: 0 !important
    }

    .range_services {
        padding: 40px 0
    }

    .services_about {
        padding: 40px 0
    }

    .our_platforms {
        padding: 40px 0
    }

    .top_notch {
        padding: 40px 0 30px
    }

    #TopNotch .MS-content .item {
        width: 25%
    }

    .white_bg_counter_box p {
        font-size: 40px !important;
        font-weight: 500 !important;
    }

    .counter_content {
        font-size: 16px;
        line-height: 20px
    }

    .range_services .work_culture_content p {
        min-height: 50px
    }

    .work_culture_content {
        margin-top: 10px
    }

    .wordPress_development_about {
        padding: 40px 0
    }

    .Company_offers {
        padding: 40px 0
    }

    .Company_offers_section .technology_section {
        margin-top: 20px
    }

    .technology_section .visit_title {
        margin-top: 20px
    }

    .Company_offers_section .carousel-indicators {
        bottom: -20px
    }

    .color_circle_box {
        height: 30px;
        width: 30px
    }

    .visit_title {
        font-size: 24px;
        line-height: 35px
    }

    .Company_offers p {
        font-size: 16px;
        line-height: 22px
    }

    .Company_offers_section {
        text-align: center
    }

    .value_added {
        padding: 40px 0
    }

    .value_added_content {
        margin-top: 25px
    }

    .service_img_circle img {
        height: 60px;
        width: 60px
    }

    .Consult_expert {
        padding: 40px 0
    }

    .contact_heading {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500
    }

    .expertise_box {
        min-height: 420px
    }

    .app_detail_box {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        font-size: 18px
    }

    .app_detail_box img {
        margin: 0 0 9px 0
    }

    .our_iphone_development_project,
    .node-padding,
    .related-technologies {
        padding: 40px 0 !important;
    }

    .our_iphone_development_project .Company_offers_section {
        margin-top: 0
    }

    .title_line {
        font-size: 22px
    }

    .development_content {
        font-size: 20px
    }

    .hire_developer .app_feature_box {
        padding: 30px 20px
    }

    .blog_section_about {
        padding: 40px 0
    }

    .blog_section_about .pagination a {
        font-size: 16px;
        line-height: 18px;
        padding: 4px 4px;
    }

    .blog_section_about .pagination li {
        font-size: 16px;
        line-height: 18px;
        padding: 4px 4px;
    }

    .Previous_anchor {
        margin-right: 0
    }

    .next_anchor {
        margin-left: 0
    }

    .blog_section_about .blog_box p {
        font-size: 16px;
        line-height: 26px;
        min-height: 150px
    }

    .subscribe_content {
        font-size: 20px;
        line-height: 30px
    }

    .add-on .input-group-btn>.btn {
        height: 40px;
        font-size: 14px;
    }

    .add-on input {
        min-height: 40px;
        font-size: 14px;
        min-width: 200px
    }

    .blog_heading {
        font-size: 30px;
        line-height: 36px;
    }

    .blog_inner_section .bloger_name_date.home_small_text {
        margin-bottom: 15px
    }

    .top_img img {
        margin: 10px 0 25px 0
    }

    .blog_grey_bg {
        padding: 40px 0
    }

    .inner_blog_sub_title {
        padding: 0;
        margin: 0 0 10px 0
    }

    .blog_inner_section {
        padding: 95px 0 40px 0
    }

    #portfolio-filter li {
        max-width: 100px;
        margin-right: 10px !important;
        font-size: 14px
    }

    #portfolio-filter a {
        min-height: 40px !important
    }

    .blog_inner_section p {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 8px;
    }

    .blog_social_icon_section a img {
        height: 40px !important;
        width: 40px !important;
    }

    .blog_inner_section .share-social-icon h4 {
        font-size: 18px;
    }

    .blog_inner_section .share-social-icon img {
        height: 20px;
        width: 20px;
    }

    .related-post h2 {
        font-size: 28px !important;
        line-height: 32px !important;
    }

    .related-post a .related-single p {
        font-size: 20px !important;
        line-height: 26px !important;
    }

    .feature-post-section {
        padding: 10px !important;
    }

    .feature-post-section h3 {
        margin-bottom: 0px !important;
    }

    .feature-post-section a .single-post span {
        height: 25px !important;
        width: 25px !important;
        margin-right: 10px !important;
    }

    .feature-content p {
        font-size: 14px !important;
        line-height: 18px !important;
    }

    .img-bottom {
        margin-bottom: 25px !important;
    }

    .search-box .search-input input {
        height: 45px !important;
    }

    .search-box .search-input button {
        height: 45px !important;
        width: 45px !important;
    }

    .search-box .search-input button img {
        width: 20px !important;
    }
}

@media (max-width:767px) {

    .line,
    .main_title {
        font-size: 25px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 5px
    }

    .main_title .line::after {
        margin: 10px 0 5px 0;
        margin-left: calc(50% - 40px);
        width: 80px
    }

    .sub_title {
        font-size: 20px
    }

    .faq_section .card-link {
        font-size: 14px;
        font-weight: 500
    }

    .top_header .nav-link {
        padding-top: 5px;
        padding-bottom: 5px
    }

    .carousel-caption {
        top: 0;
        left: 0;
        right: 0;
        padding-top: 0;
        padding-bottom: 0
    }

    .slider_text_title span {
        font-size: 35px;
        line-height: 35px;
        font-weight: 600;
        text-align: center
    }

    .slider_small_title {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        text-align: center
    }

    .carousel-item {
        height: 100%
    }

    .slider_text {
        font-size: 16px;
        line-height: 22px;
        float: left;
        text-align: center
    }

    #mixedSlider {
        margin: 0
    }

    .slider_logo {
        display: none !important
    }

    .number_images .number_images_row:first-child {
        display: none !important
    }

    .number_images {
        justify-content: unset
    }

    .number_images_row {
        margin-top: 0
    }

    .number_images_row h5 {
        font-size: 16px;
        margin-bottom: 5px
    }

    .number_images_row p {
        font-size: 12px;
        line-height: 14px;
        min-height: 30px;
        margin-bottom: 10px
    }

    .slider_main .carousel-inner {
        height: 425px
    }

    .slider_text_title {
        font-size: 40px
    }

    .slider_right_img img {
        max-width: 300px
    }

    .slider_right_img {
        display: flex;
        justify-content: center
    }

    .slider_main {
        margin-top: 56px
    }

    .about_section p,
    .about_we p,
    .career_content p {
        margin: 0 0 15px 0;
        line-height: 25px;
        font-size: 16px
    }

    .ResSlid0 .item {
        width: 50% !important
    }

    .expert_text {
        font-size: 22px;
        line-height: 30px
    }

    .team_single_box {
        margin-bottom: 25px
    }

    .teams_box {
        margin-top: 20px;
        margin-bottom: 0
    }

    .blog_box {
        margin-bottom: 20px
    }

    .Company_offers,
    .Consult_expert,
    .about_company,
    .about_section,
    .about_we,
    .blog_section_about,
    .blog_sectoin,
    .career_about,
    .client_section,
    .current_openings,
    .life_section,
    .modal_expert,
    .modal_factor_section,
    .our-portfolio,
    .our_iphone_development_project,
    .our_platforms,
    .partner_section,
    .portfolio_section,
    .services_about,
    .teams_section,
    .testimonials_section,
    .timeline_section,
    .value_added,
    .wordPress_development_about,
    .work_culture,
    .node-padding,
    .related-technologies {
        padding: 25px 0 !important;
    }

    .about_us_page {
        height: 200px
    }

    .banner_title {
        font-size: 22px;
        font-weight: 500;
        line-height: 25px
    }

    .banner_sub_title {
        font-size: 16px
    }

    .banner_title::after {
        margin: 10px 0;
        margin-left: calc(50% - 50px)
    }

    .left_timeline {
        border: none;
        padding: 10px 15px 0 15px;
        align-items: center
    }

    .right_timeline {
        padding: 0 15px 20px 15px;
        align-items: center
    }

    .timeline_years::after {
        content: " ";
        display: block;
        position: absolute;
        height: 2px;
        background: #00c5de;
        width: 80%;
        left: 100%;
        top: 50%
    }

    .timeline_years::before {
        content: " ";
        display: block;
        position: absolute;
        height: 2px;
        background: #00c5de;
        width: 80%;
        left: -118px;
        top: 50%
    }

    .timeline_years {
        border: 2px solid #00c5de;
        box-shadow: none
    }

    .left_timeline p,
    .right_timeline p,
    .timeline_title {
        text-align: center !important;
        width: 100%
    }

    .about_text {
        font-size: 16px;
        line-height: 25px;
        text-align: center !important
    }

    .modal_box_right {
        padding: 15px
    }

    .modal_box2 .modal_title2 {
        font-size: 18px
    }

    .modal_box2 p {
        font-size: 16px;
        text-align: center !important
    }

    .project-details svg {
        margin: 25px 0 10px 0
    }

    .work_culture_img {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center
    }

    .work_culture_inner_content {
        width: 100%;
        text-align: center
    }

    .work_culture_img img {
        margin-bottom: 10px
    }

    .employee_speaks_slider p {
        padding: 0 10px
    }

    .employee_speaks .carousel-indicators {
        bottom: -7%
    }

    .life_section .thumb {
        margin-bottom: 10px
    }

    .view_more_photos {
        padding: 0 0 10px 0
    }

    .white_bg_counter_box {
        margin-top: 20px !important;
    }

    .white_bg_counter_box p {
        font-size: 28px !important;
        line-height: 28px !important;
    }

    #TopNotch .MS-content .item {
        width: 50%
    }

    .range_services .work_culture_content {
        margin-bottom: 20px
    }

    .development_title {
        font-size: 16px;
        line-height: 16px
    }

    .rang_services_box {
        margin-bottom: 10px
    }

    .contact_deatail_content .main_btn {
        padding: 0 20px
    }

    .Company_offers_section .carousel-item {
        min-height: 860px
    }

    .color_circle_box {
        height: 30px;
        width: 30px
    }

    .visit_section {
        margin-top: 20px
    }

    .contact_heading {
        font-size: 22px;
        line-height: 30px;
        font-weight: 500
    }

    .expertise_box {
        min-height: auto
    }

    .our_iphone_development_project .Company_offers_section {
        margin-top: 0
    }

    .hire_developer .app_feature_box {
        padding: 30px 15px
    }

    .hire_developer .app_feature_title {
        font-size: 15px
    }

    .tools_title {
        padding-top: 20px;
        margin-bottom: 20px;
        font-size: 25px;
        font-weight: 500
    }

    .modal_expert_text {
        font-size: 22px;
        padding: 0 0 15px 0;
        line-height: 30px
    }

    .add-on {
        margin-top: 10px
    }

    .blog_search_box {
        max-width: 100%
    }

    .subscribe_content {
        font-size: 18px;
        line-height: 25px
    }

    .blog_heading {
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        text-align: left;
    }

    .blog_inner_section p {
        font-size: 14px;
        line-height: 18px;
        text-align: left;
    }

    .blog_section_about .pagination a {
        font-size: 14px;
        padding: 3px
    }

    .blog_section_about .pagination li {
        padding: 0 5px;
        font-size: 14px;
        margin: 0
    }

    .blog_inner_section {
        padding: 85px 0 25px 0
    }

    .blog_inner_section table {
        display: block;
        width: 100%;
        overflow-x: auto;
    }

    .blog_inner_section table::-webkit-scrollbar-thumb {
        background: #000;
    }

    .blog_inner_section table::-webkit-scrollbar {
        height: 5px;
    }

    nav#porfolio-nav a {
        text-indent: 0 !important
    }

    #portfolio-filter li {
        margin: 0 5px !important
    }

    .testimonials_section .testimonial {
        min-height: auto
    }

    .bottom_footer {
        font-size: 14px
    }

    .testimonials_video_slider_main .leftRs,
    .testimonials_video_slider_main .rightRs {
        margin-top: 60px
    }

    .social_icon_box {
        margin: 0 10px
    }

    .our-services {
        padding-bottom: 0
    }

    .footer_section {
        padding: 0
    }

    .top_modal_header {
        padding: 15px
    }

    .top_modal_header .modal-title {
        font-size: 22px
    }

    .title_bottom_text {
        font-size: 16px;
        line-height: 20px;
        padding-top: 10px
    }

    .modal_left_content {
        padding: 0
    }

    .inner_modal_section {
        padding: 15px 0
    }

    .modal_ul {
        padding: 0 0 0 25px;
        margin: 0
    }

    .modal_ul li {
        margin-bottom: 5px
    }

    .modal_right_content {
        padding: 0 0
    }

    .blog_top_section .blog_top_content h2 {
        font-size: 20px;
        line-height: 24px;
    }

    .blog_top_content {
        margin-bottom: 20px;
    }

    .blog_top_section .blog-list .blog-list-img {
        width: 75px;
        height: 75px;
        object-fit: cover;
    }

    .blog-list-content .last-child-p {
        font-size: 12px;
    }

    .last-child-p img {
        width: 15px;
    }

    .blog_top_section,
    .popular-section,
    .tranding-section,
    .explore-section,
    .best-thing-sec,
    .footer-sec-black {
        padding: 20px 0px;
    }

    .blog_top_section .blog-list .blog-list-img {
        margin-right: 10px;
    }

    .popular-section .popular-title h2::after,
    .tranding-section .tranding-main h2::after,
    .tranding-section .categories-main h3::after,
    .explore-section .explore-title h2::after {
        display: none;
    }

    .popular-section .popular-title h2,
    .tranding-section .tranding-main h2,
    .tranding-section .categories-main h3,
    .explore-section .explore-title h2 {
        font-size: 24px;
        line-height: 26px;
        text-align: center;
    }

    .popular-section .popular-title {
        margin-bottom: 15px;
    }

    .single-popular {
        flex-direction: column-reverse;
    }

    .popular-section .single-popular {
        padding: 20px;
    }

    .popilar-img {
        margin-bottom: 20px;
    }

    .popular-content {
        margin-right: 0px !important;
    }

    .popular-section .single-popular .popular-content {
        text-align: center;
    }

    .popular-section .single-popular .popular-content h5 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 5px;
    }

    .popular-section .single-popular .popular-content .main_btn {
        margin-top: 10px;
        height: 35px;
        line-height: 12px;
    }

    .tranding-section .tranding-main h2,
    .tranding-section .categories-main h3 {
        margin-bottom: 20px;
    }

    .tranding-list .tranding-list-content a h5 {
        font-size: 16px;
        line-height: 20px;
    }

    .blog-list-content .last-child-p {
        line-height: 22px;
    }

    .tranding-section .tranding-main .tranding-list .tranding-list-img {
        min-width: 80px;
        height: 80px;
    }

    .tranding-main {
        margin-bottom: 10px;
    }

    .categories-list {
        padding-left: 0px !important;
        border-left: 0px !important;
    }

    .explore-section .explore-title p {
        text-align: center;
    }

    .explore-section .explore-title h2 {
        margin-bottom: 10px;
    }

    .explore-section .explore-list {
        height: 35px;
        margin-bottom: 12px !important;
    }

    .best-thing-sec .thing-text {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 10px;
    }

    .best-thing-sec p {
        font-size: 16px;
    }

    .add-on .input-group-btn>.btn,
    .add-on input {
        min-height: 35px;
        height: 35px;
    }

    .tranding-list .tranding-list-content p {
        margin-bottom: 5px;
    }

    .footer-sec-black {
        text-align: center;
    }

    .border-right {
        margin-bottom: 10px;
    }

    .footer-sec-black a {
        font-size: 12px;
        line-height: 22px;
    }

    .footer-sec-black h3 {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .related-post h2::after {
        display: none !important;
    }

    .related-post h2 {
        font-size: 24px !important;
        line-height: 26px !important;
        text-align: center;
    }

    .node-circle {
        margin: auto !important;
    }

    .node-js-content {
        text-align: center;
    }

    .more-btn {
        display: flex;
        justify-content: center;
    }

    .blog_top_section .blog_top_content h2,
    .blog_top_section .blog_top_content h2 a {
        font-size: 20px;
        line-height: 26px;
    }

    .blog-list-content h4,
    .blog-list-content h4 a {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width:576px) {

    .carousel-caption .main_btn,
    .main_btn {
        font-size: 14px
    }

    .navbar {
        padding: 5px 10px
    }

    .top_header .nav-link {
        font-size: 16px
    }

    .navbar-collapse {
        padding: 0 10px
    }

    .pink_box {
        width: 33.33%;
        margin: 60px 0 0 0;
        background-size: 110px
    }

    .pink_box-info {
        position: absolute;
        text-align: center;
        top: 34px;
        left: 16px
    }

    .blue_box-info h2,
    .green_box-info h2,
    .pink_box-info h2,
    .purple_box-info h2,
    .red_box-info h2,
    .yellow_box-info h2 {
        font-size: 18px
    }

    .blue_box-info h4,
    .green_box-info h4,
    .pink_box-info h4,
    .purple_box-info h4,
    .red_box-info h4,
    .yellow_box-info h4 {
        font-size: 12px
    }

    .purple_box {
        width: 33.33%;
        margin: 0;
        background-size: 110px
    }

    .purple_box-info {
        position: absolute;
        text-align: center;
        top: 25px;
        left: 18px
    }

    .green_box {
        width: 33.33%;
        margin: 60px 0 0 0;
        background-size: 110px
    }

    .green_box-info {
        position: absolute;
        text-align: center;
        top: 32px;
        left: 12px
    }

    .blue_box {
        width: 50%;
        height: 150px;
        margin: -120px auto 0 90px;
        background-size: 130px;
        background-position: center
    }

    .blue_box-info {
        position: absolute;
        text-align: center;
        top: 50px;
        left: 50px
    }

    .yellow_box {
        width: 33.33%;
        height: 120px;
        background-size: 110px
    }

    .yellow_box-info {
        position: absolute;
        text-align: center;
        top: 43px;
        left: 33px
    }

    .red_box {
        width: 33.33%;
        height: 120px;
        background-size: 110px
    }

    .red_box-info {
        position: absolute;
        text-align: center;
        top: 35px;
        left: 25px
    }

    .sub_title {
        font-size: 18px;
        line-height: 26px
    }

    .blog_box {
        padding: 20px
    }

    .testimonials_section .testimonial {
        font-size: 18px;
        line-height: 30px
    }

    .send_button {
        right: 20px;
        margin-top: -45px
    }

    .bottom_footer {
        font-size: 14px;
        flex-flow: wrap
    }

    .testimonials_video_slider_main .leftRs,
    .testimonials_video_slider_main .rightRs {
        margin-top: 100px
    }

    .breadcrumb .breadcrumb-item a {
        font-size: 13px
    }

    .breadcrumb .breadcrumb-item.active {
        font-size: 13px;
        font-weight: 500
    }

    .breadcrumb-item,
    .breadcrumb-item+.breadcrumb-item {
        display: flex;
        padding-left: 0
    }

    .breadcrumb-item+.breadcrumb-item::before {
        padding-right: 2px
    }

    .footer_address,
    .footer_sub_title,
    .footer_call,
    .footer_call a,
    .social_icon_title {
        text-align: center;
    }

    .footer_social_icon_section {
        justify-content: center;
    }

    .testimonials_section .testimonial {
        height: auto;
    }
}

@media (max-width:392px) {

    /* .add-on .input-group-btn>.btn {
        margin-top: -30px;
        margin-right: -145px;
    } */
    .navbar-brand,
    .navbar-brand img {
        max-width: 170px;
    }

    .popular-section .single-popular .popilar-img {
        min-width: 230px;
    }

    .tranding-list .tranding-list-content .last-child-p {
        font-size: 12px;
    }

    .tranding-section .tranding-main .tranding-list .tranding-list-img {
        min-width: 70px;
        height: 70px;
    }

    .blog_top_section .blog-list .blog-list-img {
        min-width: 70px;
        min-height: 70px;
    }
}

@media (max-width:375px) {
    .blue_box-info {
        top: 47px;
        left: 42px
    }
}

.clearfix {
    clear: both;
    display: table;
    content: "";
}

.share-social-icon .blog_social sb-wrapper sb-show-icon {
    background-color: white;
    border: none;
}

/* .blog_social .sb-button button {
	height: 45px;
	width: 45px;
	background-color: #00c5de;
	border: 1px solid #00c5de;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-right: 20px;
	cursor: pointer;
	padding: 7px 0;
	transition: all 0.5s;
} */